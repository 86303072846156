import {createFileRoute} from "@tanstack/react-router";
import {COLOR_WHITE, FullWithDesignedSection, Layout, VStack} from "@seeair/shared-components";
import React from "react";
import {Widget} from "@typeform/embed-react";

export const Route = createFileRoute('/quiz')({
    /*
    validateSearch: z.object({result: z.enum(['pass', 'fail', 'ineligible']), score: z.number().optional()}),
    component: function QuizResultComponent() {
        const {result, score} = Route.useSearch()
        let message
        let subMessage
        if (result == 'pass') {
            message = "Congrats! You owned that homeowner quiz."
            subMessage = "Keep an eye on your front doorstep for a gift from us."
        } else if (result == 'fail') {
            message = "Sadly, you didn't pass the quiz. Might be time to get the experts at SeeAir involved."
            subMessage = "We'll send you a consolation prize anyways."
        } else {
            message = "Sorry, this contest is just for homeowners in Massachusetts."
            subMessage = "We've added you to our subscriber list so that you can stay tuned on our progress and learn home improvement tips."
        }
        return <Layout>
            <FullWithDesignedSection grow background={COLOR_WHITE}>
                <div className="flex flex-col h-full w-full rounded-2xl p-10 md:-mr-20 max-w-96" style={{
                    background: "linear-gradient(111deg, rgba(202, 240, 248, 0.80) -5.88%, rgba(255, 255, 255, 0.80) 19.61%, rgba(255, 255, 255, 0.80) 49.34%, rgba(202, 240, 248, 0.80) 91.69%)",
                    boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.20)"
                }}>
                    <Text2Xl center>{message}</Text2Xl>
                    <TextBase center>{subMessage}</TextBase>
                    {score && <TextBase light center>Score {score} / 5</TextBase>}
                </div>
            </FullWithDesignedSection>
        </Layout>
    }
     */
    component: function QuizResultComponent() {
        return <Layout>
            <FullWithDesignedSection grow background={COLOR_WHITE}>
                <VStack center>
                    <div className="flex flex-col h-full w-full rounded-2xl p-10 md:-mr-20 w-max-200" style={{
                        background: "linear-gradient(111deg, rgba(202, 240, 248, 0.80) -5.88%, rgba(255, 255, 255, 0.80) 19.61%, rgba(255, 255, 255, 0.80) 49.34%, rgba(202, 240, 248, 0.80) 91.69%)",
                        boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.20)"
                    }}>
                        <Widget id={"hkgUetSb"} className={"quiz"}
                                autoFocus={true}
                                opacity={0}
                                style={{
                                    justifyContent: "flex-start",
                                }}
                                hideHeaders={true}
                                disableScroll={true}
                                autoResize={true}
                                hideFooter={false}
                                inlineOnMobile={true}
                                noScrollbars={true}
                        />
                    </div>

                </VStack>
            </FullWithDesignedSection>
        </Layout>
    }
})
