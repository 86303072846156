import { createFileRoute } from '@tanstack/react-router'
import {trpc} from "~/lib-client";
import {BlogPage} from "@seeair/homeowner-components";

export const Route = createFileRoute('/blog/_blog/$blog_id')({
  component: function BlogPageComponent(){
    const {blog_id} = Route.useParams()
    const [blog] = trpc.PUBLIC.getBlog.useSuspenseQuery({blog_id})
    return <BlogPage blog={blog}/>
  }
})