import {z, ZodType} from "zod";
import { extendZodWithOpenApi } from '@asteasolutions/zod-to-openapi';
import dayjs from "dayjs";
import {
    GetHomeSchema,
    GetSharesSchema,
    GetAssessmentSchema,
    Assessment, GetRecommendationsSchema, GetQuotesSchema, GetUserSchema
} from "./refined-tables.js";
import {
    AssessmentFile,
    AssessmentFileSchema, AssessmentFilesEnumSchema, EnergyDataRecord, HomeDetails,
    RecDataSchema,
    RRecommendationSchema
} from "./jsonb-schemas.js";


export const KnownHomeDetailsKeys = {
    Beds: 'beds',
    Baths: 'baths',
    Sqft: 'sqft',
    Floors: 'floors',
    Cooling: 'cooling',
    Heating: 'heating',
} as const




export function validateArray<T>(array: Array<any>, zod: ZodType<T>,throwOnInvalidMessage?:string): Array<T> {
    const validatedData: Array<T> = []
    let invalidCount = 0
    for (const a of array) {
        const parseResult = zod.safeParse(a)
        if (!parseResult.success) {
            invalidCount++
            console.log(`validation error`)
            console.log(parseResult.error)
        } else {
            validatedData.push(parseResult.data)
        }
    }
    if(throwOnInvalidMessage&&invalidCount>0){
        throw `${throwOnInvalidMessage}: ${invalidCount} of ${array.length}  invalid`
    }
    return validatedData
}
extendZodWithOpenApi(z);

export type RRecommendationData = z.infer<typeof RRecommendationSchema>
export const RecInputSchema = z.object({
    original_rec_id: z.string(),
    title: z.string(),
    description: z.string().nullish(),
    category: z.string(),
    type: z.string(),
    rec_data: z.object({
        Annual_Savings_High: z.coerce.number(),
        Annual_Savings_Low: z.coerce.number(),
        Net_Cost_Low: z.coerce.number(),
        Net_Cost_High: z.coerce.number(),
        Upfront_Cost_Low: z.coerce.number(),
        Upfront_Cost_High: z.coerce.number(),
        Prerequisites: z.array(z.string()).optional(),
    }),
})
export type RecInputData = z.infer<typeof RecInputSchema>
export const RecManualInputDataSchema = RecInputSchema.merge(z.object({
    category: z.string().optional(),
    type: z.string().optional(),
}))

export type RecManualInputData = z.infer<typeof RecManualInputDataSchema>


export type LocationAssessment = {
    locationName: string
    infiltration: number
    insulation: number
    electrification: number
}



export const AwairDataSchema = z.record(z.string(), z.array(z.any()))

export const UserSchema = z.object({
    email: z.string(),
    id: z.string(),
    name: z.string().optional().nullable(),
    image: z.string().optional().nullable(),
    role: z.string().optional().nullable(),
})

export type User = z.infer<typeof UserSchema>

export const RentcastPropertiesSchema = z.object({
    // formattedAddress:z.string(),
    // addressLine1:z.string(),
    // addressLine2:z.string().nullish(),
    // city:z.string(),
    // state:z.string(),
    // zipCode:z.string(),
    // county:z.string(),
    // latitude:z.number(),
    // longitude:z.string(),
    bedrooms: z.number().optional(),
    bathrooms: z.number().optional(),
    yearBuilt: z.number().optional(),
    squareFootage: z.number().optional(),
    features: z.object({
        fireplace:z.boolean().optional(),
        floorCount:z.number().optional(),
        // heating:z.boolean(),
        // cooling:z.boolean(),
        // roofType:z.string(),
        // coolingType:z.string(),
        // heatingType:z.string(),
    }).optional()
})
export type RentcastProperties = z.infer<typeof RentcastPropertiesSchema>



export const RecommendationTemplateSchema = z.object({
    id: z.string(),
    title:  z.string(),
    icon:  z.string().nullish(),
    deal: z.enum(["No Deal","Deal"]),
    type: z.enum(["DIY","PRO"]),
    healthCategory: z.number().max(3),
    climateCategory: z.number().max(3),
    priceCategory: z.number().max(3),
    category:  z.enum(["Electrification","Insulation","Infiltration"]),
    description:  z.string().nullish(),
    healthSummary: z.string().nullish(),
    climateSummary:z.string().nullish(),
    priceSummary:z.string().nullish(),
    healthDescription: z.string().nullish(),
    climateDescription:z.string().nullish(),
    priceDescription:z.string().nullish(),
    prerequisites: z.array(z.string()),
    projectButtonText: z.string().nullish(),
    projectImageUrl: z.string().nullish(),
    projectUrl: z.string().nullish()
})
export type RecommendationTemplate = z.infer<typeof RecommendationTemplateSchema>
export const FileUploadNonceSchema = z.object({
    assessment_id: z.string(),
    assessmentFileEnum: AssessmentFilesEnumSchema,
    fileRecord: AssessmentFileSchema
})
export type FileUploadNonce = z.infer<typeof FileUploadNonceSchema>

export const AuthzRolesSchema = z.enum(['site_admin','owner','write','read'])
export type AuthzRoles = z.infer<typeof AuthzRolesSchema>

export const AssessmentUpdateEventSchema = z.enum([
    "file-saved",
    "meeting-saved",
    "meeting-removed",
    "note-saved",
    "home-details-updated",
    "energy-data-saved",
    "assessment-data-updated",
    "solar-data-saved",
    "rec-added",
    "rec-deleted",
    "rec-hidden",
    "status-changed"
])
export type AssessmentUpdateEvent = z.infer<typeof AssessmentUpdateEventSchema>

export const ExternalSyncRecordSchema = z.object({
    event:AssessmentUpdateEventSchema,
    assessment_id:z.string(),
})
export type ExternalSyncRecord = z.infer<typeof ExternalSyncRecordSchema>


export function getLatestAssessment(assessments: Array<Assessment>): Assessment | undefined {
    let latest
    for (const assessment of assessments) {
        if (!latest || dayjs(assessment.created_at).isAfter(latest.created_at)) {
            latest = assessment
        }
    }
    return latest
}

extendZodWithOpenApi(z);


export const RecommendationAggregateSchema = GetRecommendationsSchema
    .omit({rec_data: true})
    .merge(z.object({rec_data: RecDataSchema}))
    .merge(z.object({
        quotes: z.array(GetQuotesSchema.openapi("quote")),
        selectedQuote: GetQuotesSchema.nullish().openapi("quote")
    }))
    .openapi("recommendation")
export type RecommendationAggregate = z.infer<typeof RecommendationAggregateSchema>
export const HomeAggregateSchema = GetHomeSchema.merge(z.object({
    shares: z.array(GetSharesSchema.openapi("share")),
    assessments: z.array(GetAssessmentSchema.openapi("assessment")),
    /* for the life of me i can't figure out why we need to omit, then re-merge `rec_data` if we don't to this it shows up as a ZodLazy (with incorrect fields) to our code generator*/
    recommendations: z.array(RecommendationAggregateSchema),
    quotes: z.array(GetQuotesSchema.openapi("quote")),
    permissions: z.set(AuthzRolesSchema).openapi("permissions", {type: "object"})
})).openapi("home")
export type HomeAggregate = z.infer<typeof HomeAggregateSchema>

export const UserAggregateSchema = GetUserSchema.merge(z.object({
    homes:z.array(HomeAggregateSchema)
}))
export type UserAggregate = z.infer<typeof UserAggregateSchema>

export const AssessmentThresholdsSchema = z.record(
    z.string(),
    z.array(z.object({color: z.string(), simpleCondition: z.string()}))
)
export type AssessmentThresholds = z.infer<typeof AssessmentThresholdsSchema>
export const HomeDetailsFieldInfoSchema = z.object({
    type: z.string(),
    id: z.string(),
    title: z.string(),
    default: z.string().optional(),
    options: z.array(z.string())
})
export type HomeDetailsFieldInfo = z.infer<typeof HomeDetailsFieldInfoSchema>



const primaryGasHeaters:Array<HomeDetails["primary_heating"]> = ['Central gas furnace','Gas boiler']
const secondaryGasHeaters:Array<HomeDetails["secondary_heating"]> = ['Central gas furnace','Gas boiler','Room (through-the-wall) gas furnace']

export function requiresGasInput(details:HomeDetails): boolean {
    if(!details.stove || !details.primary_heating) {
        return true //we don't know yet, they need to fill in more details
    }
    if(details.stove == 'Gas') {
        return true
    }
    if(primaryGasHeaters.includes(details.primary_heating)){
        return true
    }
    if(secondaryGasHeaters.includes(details.secondary_heating)){
        return true
    }
    return false
}

function assessmentFileIsMissing(file: Array<AssessmentFile> | null | undefined):boolean {
    if(!file){
        return true
    }
    return file.length == 0
}
function isEnergyUsageEmpty(usage:Record<string,EnergyDataRecord>|null|undefined):boolean {
    if(!usage){
        return true
    }
    return Object.keys(usage).length == 0
}
export type ValidationResponse = {valid:true}|{valid:false,errors:Array<string>}

export function validateHomeownerInputs(assessment:Assessment):ValidationResponse {
    const errors:Array<string>=[]
    if(assessmentFileIsMissing(assessment.assessment_files?.capture_video)){
        errors.push("Capture Video Not Uploaded")
    }
    if(assessmentFileIsMissing(assessment.assessment_files?.window_image)){
        errors.push("Window Image Not Uploaded")
    }
    if(!assessment.home_details?.stove) {
        errors.push("Home Details - `Stove` is missing")
    }
    if(!assessment.home_details?.primary_heating) {
        errors.push("Home Details - `Primary Heating` is missing")
    }
    if(isEnergyUsageEmpty(assessment.electric_usage)){
        errors.push("Electric Usage Missing")
    }
    if(requiresGasInput(assessment.home_details||{})){
        if(isEnergyUsageEmpty(assessment.gas_usage)){
            errors.push("Gas Usage Missing")
        }
    }
    if(errors.length == 0){
        return {valid:true}
    } else {
        return {valid:false,errors}
    }
}