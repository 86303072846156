import './index.css'
import '@mantine/core/styles.layer.css'
import '@mantine/notifications/styles.layer.css'
import '@mantine/dates/styles.layer.css'
import dayjs from 'dayjs'
import ReactDOM from 'react-dom/client'
import {App} from '~/app'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import * as Sentry from '@sentry/react'

Sentry.init({
	dsn: 'https://066c6c425beb23e9e2725126bdb6aabe@o4507192755224576.ingest.us.sentry.io/4507207104856064',
	enabled: import.meta.env.VITE_DEPLOYMENT_ENV !== 'local',
	attachStacktrace: true,
	environment: import.meta.env.VITE_DEPLOYMENT_ENV,
	integrations: [
		Sentry.browserTracingIntegration({enableInp: true}),
		Sentry.browserProfilingIntegration(),
		Sentry.replayIntegration({maskAllText: false, blockAllMedia: false}),
		// Sentry.feedbackIntegration(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	// tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	profilesSampleRate: 1.0,
})

dayjs.extend(duration)
dayjs.extend(relativeTime)

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(<App />)
