import {createFileRoute} from '@tanstack/react-router'

import {z} from 'zod'
import {AppRedirect} from "@seeair/homeowner-components";
import {useQuery} from "@tanstack/react-query";
import {SuspenseLoader} from "@seeair/shared-components";
import {AuthenticatedLoad} from "~/lib-client";

const tokenResponseSchema = z.object({token:z.string()})
export const Route = createFileRoute('/webview/app-redirect')({
	component: function AppRedirectComponent(){
		const {data:url} = useQuery({
			queryKey:["auth","token"],
			queryFn: async () => {
				const res = await fetch('/auth/token')
				if (res.ok) {
					const tokenResponse = tokenResponseSchema.parse(await (res).json())
					return `com.seeair.healthyhome://seeair.com/login?${new URLSearchParams(tokenResponse)}`
				} else {
					return null
				}
			}})
		if(!url){
			return SuspenseLoader
		}
		return <AppRedirect url={url} />
	},
	beforeLoad:AuthenticatedLoad,
	validateSearch: z.object({redirect: z.string().optional()})
})


