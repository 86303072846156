import {
    DesignedBenefitsSection, DesignedCaroselSection,
    DesignedHeroSection,
    DesignedIllustrationSection,
    Layout
} from "@seeair/shared-components";

export function HomePage(){
    return (
        <Layout newsletterSurvey headerOverlap>
            <DesignedHeroSection/>
            <DesignedBenefitsSection/>
            <DesignedIllustrationSection/>
            <DesignedCaroselSection/>
        </Layout>
    )
}