import {Link, createFileRoute} from '@tanstack/react-router'
import {GradientBox, TextBase, TextLg, TextLink, TextSm, VStack} from "@seeair/shared-components";

export const Route = createFileRoute('/auth/_auth/verify-request')({
	beforeLoad(ctx) {
		if (ctx.context.user) {
			throw ctx.navigate({to: '/homes'})
		}
	},
	component: () => (
		<VStack center>
			<GradientBox>
				<VStack center>
					<TextLg>
						Check your email
					</TextLg>
					<TextBase>A sign in link has been sent to your email address.</TextBase>
					<Link
						preload={false}
						to="/auth/signin"
					><TextLink>Go back</TextLink></Link>
					<TextSm>
						Can't find your link? Check your spam folder!
					</TextSm>
				</VStack>
			</GradientBox>
		</VStack>
	),
})
