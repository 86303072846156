import {HubspotBlogAggregate} from "@seeair/schemas";
import {
    COLOR_WHITE,
    FullWithDesignedSection,
    HStack,
    Text4Xl,
    TextBase,
    TextLg,
    VStack
} from "@seeair/shared-components";
import {shortDateFormat} from "@seeair/shared-components";
import React from "react";
import {trpc} from "~/lib-client";
import {BlogSection} from './BlogSection.js';

export function BlogPage({blog}: { blog: HubspotBlogAggregate }) {
    const {isLoading: isBlogsLoading, data: blogs, isError: isBlogsError} = trpc.PUBLIC.getLatestBlogs.useQuery()
    return <VStack>
        <FullWithDesignedSection background={COLOR_WHITE}>
            <VStack>
                <Text4Xl>{blog.name}</Text4Xl>
                <HStack center>
                    <img src={blog.author.avatar || '/design-assets/avatar-placeholder.png'} style={{
                        borderRadius: '100%',
                        height: "6rem",
                        width: "6rem"
                    }}/>

                    <VStack classNames="ml-4">
                        {blog.author.displayName && <HStack classNames="items-center"><TextLg m0
                                                                                              classNames="mr-4">{blog.author.displayName}</TextLg>{/*blog.author.bio && <InfoTooltip label={blog.author.bio} />*/}
                        </HStack>}
                        {blog.publishDate && <TextBase light m0>{shortDateFormat(blog.publishDate)}</TextBase>}
                    </VStack>
                </HStack>
                <div dangerouslySetInnerHTML={{'__html': blog.postBody}}/>
            </VStack>
        </FullWithDesignedSection>
        <BlogSection title={"Recent Posts"} skipId={blog.id} />
    </VStack>
}