import {Avatar} from '@mantine/core'
import {type User} from '@seeair/schemas'
import {TextBase, TextSm, VStack} from "./DesignBase.js";

export function UserBadge({user,className}: {user: User; className?: string}) {
    return (
        <div className={className}>
            <Avatar src={user.image} imageProps={{referrerPolicy: 'no-referrer'}} />
            <VStack>
                <TextBase>
                    {user.name}
                </TextBase>
                <TextSm>
                    {user.email}
                </TextSm>
            </VStack>
        </div>
    )
}
