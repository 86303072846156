import React, {PropsWithChildren} from "react";
import { User} from "@seeair/schemas";
import {trpc} from "~/lib-client";

type UserData = {
    user:User | null
    isLoading:boolean
}
const DefaultUserData:UserData = {user:null,isLoading:true}
export const UserDataContext = React.createContext<UserData>(DefaultUserData)
export function SuspenseUserDataProvider({children}:PropsWithChildren){
    const [user] = trpc.PUBLIC.getCurrentUser.useSuspenseQuery()
    return (
        <UserDataContext.Provider value={{user,isLoading:false}}>
            {children}
        </UserDataContext.Provider>
    )
}
export function QueryUserDataProvider({children}:PropsWithChildren){
    const {data:user,isLoading} = trpc.PUBLIC.getCurrentUser.useQuery()
    return (
        <UserDataContext.Provider value={{user:user||null,isLoading}}>
            {children}
        </UserDataContext.Provider>
    )
}