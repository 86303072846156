import {
    HStack,
    RStack,
    Text2Xl,
    Text4Xl,
    TextXl,
    VStack,
    FullWithDesignedSection
} from './DesignBase.js';
import {IconCalendar, IconHeart, IconHome, IconPriceTag} from './Icons.js';
import React from "react";

export function DesignedBenefitsSection() {
    const outerClassName = "flex flex-col items-center w-64 h-72"
    const innerClassName = "flex flex-col justify-end h-28"
    return <FullWithDesignedSection background={`linear-gradient(51deg, #FFFD82 -21.6%, #FFF 19.49%, #FFF 81.1%, #FFFD82 132.27%)`} minHeight="49rem">
        <HStack center>
            <VStack wHalf>
                <Text4Xl center>A Healthy Home for a Healthy Planet</Text4Xl>
                <TextXl center>You spend ⅔ of your life at home, your home is your largest source of carbon emissions, and it is your largest expense. Our goal is to make your home healthier, reduce your climate impact and cut your energy bills.</TextXl>
            </VStack>
        </HStack>
        <div className="mt-12">
            <RStack lg>
                <RStack sm around>
                    <div className={outerClassName}>
                        <div className={innerClassName}>
                            <IconHeart/>
                        </div>
                        <Text2Xl>Healthy Home</Text2Xl>
                    </div>
                    <div className={outerClassName}>
                        <div className={innerClassName}>
                            <IconCalendar/>
                        </div>
                        <Text2Xl>Year-round comfort</Text2Xl>
                    </div>
                </RStack>
                <RStack sm around>
                    <div className={outerClassName}>
                        <div className={innerClassName}>
                            <IconHome/>
                        </div>
                        <Text2Xl>Energy savings</Text2Xl>
                    </div>
                    <div className={outerClassName}>
                        <div className={innerClassName}>
                            <IconPriceTag/>
                        </div>
                        <Text2Xl>Home value</Text2Xl>
                    </div>
                </RStack>
            </RStack>
        </div>
    </FullWithDesignedSection>
}

