import {DesignedLink} from './DesignComponents.js';
import { COLOR_LIGHT_GREY, COLOR_WHITE, PRIMARY_BLUE} from './Theme.js';
import {RStack, VStack} from './DesignBase.js';
import {Logo} from './Icons.js';
import React from "react";
import classNames from "classnames";
import {Widget} from "@typeform/embed-react";

export function FooterLink({text, to}: { text: string, to: string }) {
    return <DesignedLink to={to}
                         style={{
                             borderRadius: "6.25rem",
                             textDecoration: "none",
                             marginTop: "1rem",
                             width: "fit-content"
                         }}
                         inactiveProps={{
                             style: {
                                 color: COLOR_LIGHT_GREY,
                                 backgroundColor: "transparent"
                             }
                         }}
                         activeProps={{
                             style: {
                                 color: PRIMARY_BLUE,
                                 backgroundColor: COLOR_WHITE
                             }
                         }}>
        <span className="px-4">{text}
        </span>
    </DesignedLink>
}

export function DesignedFooter({newsletterSurvey}: { newsletterSurvey: boolean }) {
    return <div className={classNames("relative py-12 w-full", {
        "px-24": !newsletterSurvey,
        "px-4": newsletterSurvey,
        "sm:pl-14": newsletterSurvey,
        "sm:pr-24": newsletterSurvey
    })}
                style={{
                    background: `linear-gradient(135deg, ${PRIMARY_BLUE} 46.9%, #037CB0 118.11%)`
                }}>
        <RStack lg reverse between>
            <RStack lg>
                {newsletterSurvey && <div className={classNames(
                    "pt-8 border-t-2 border-r0",
                    "lg:pt-0 lg:border-t-0 lg:border-r-2")}>
                    <NewsletterSurveyWidget/>
                </div>}

                <div className={classNames(
                    "flex flex-col border-white ",
                    "pt-8 px-0 border-t-2 border-r0",
                    "lg:pt-0 lg:px-8 lg:border-t-0 lg:border-r-2")}>
                    <VStack>
                        <FooterLink to="/blog" text="Blog"/>
                        <FooterLink to="/about" text="About"/>
                        <FooterLink to="/privacy" text="Privacy Policy"/>
                        <FooterLink to="/terms" text="Terms Of Service"/>
                    </VStack>
                </div>
            </RStack>

            <div className="lg:pl-8 lg:pb-0 pb-8">
                <Logo inverse/>
            </div>
        </RStack>
    </div>
}

function NewsletterSurveyWidget() {
    return <Widget id={"JXwNCuQN"} className={"newsletter"} autoFocus={false} opacity={0} style={{
        minWidth: "28rem"
        // marginTop: "-8rem",
        // justifyContent: "flex-start",
        // transform: "scale(0.8)"
    }} hideHeaders={true} disableScroll={true} autoResize={true} hideFooter={true} inlineOnMobile={true}
                   noScrollbars={true}/>
}