import {
    Outlet,
    ScrollRestoration,
    createRootRouteWithContext,
} from '@tanstack/react-router'
import {type RouterContext} from '~/lib-client'
import React from 'react'
import * as Sentry from '@sentry/react'

const TanStackRouterDevtools =
    process.env.NODE_ENV === 'production'
        ? () => null // Render nothing in production
        : React.lazy(() =>
            // Lazy load in development
            import('@tanstack/router-devtools').then((res) => ({
                default: res.TanStackRouterDevtools,
                // For Embedded Mode
                // default: res.TanStackRouterDevtoolsPanel
            }))
        )

export type RootRouteType = typeof Route
export const Route = createRootRouteWithContext<RouterContext>()({
    component: RootComponent,
    wrapInSuspense: true,
    loader(ctx) {
        ctx.context.utils.PUBLIC.getCurrentUser.fetch().then((user) => Sentry.setUser(user || null))
    },
})

function RootComponent() {
    return (
        <>
            <ScrollRestoration/>
            {/*<Suspense fallback={SuspenseLoader}>*/}
                <Outlet/>
            {/*</Suspense>*/}

            {/*<ReactQueryDevtools buttonPosition="top-right" />*/}
            {/* <TanStackRouterDevtools position="bottom-left" /> */}
        </>
    )
}
