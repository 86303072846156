import { HomeAggregate, SeeAirUser} from "@seeair/schemas";
import {buildShortAddress, trpc} from "~/lib-client";
import {useDisclosure} from "@mantine/hooks";
import {useState} from "react";
import {notifications} from "@mantine/notifications";
import {match} from "ts-pattern";
import {IconDots, IconHome} from "@tabler/icons-react";
import {Link} from "@tanstack/react-router";
import { Collapse, Menu, UnstyledButton} from "@mantine/core";
import {modals} from "@mantine/modals";


import {SetDemoModal} from './SetDemoModal.js';
import {AssessmentSummaryRow} from './AssessmentSummaryRow.js';
import {CollapseToggle, HStack, SearchResultRow, TextBase, TextLink, VStack} from "@seeair/shared-components";

export function HomeCollapse({home, user}: {
    home: HomeAggregate
    user?: SeeAirUser
}) {
    const utils = trpc.useUtils()
    const [opened, {toggle}] = useDisclosure(true)
    const [modal, setModal] = useState<
        'set_demo'
    >()

    const deleteHome = trpc.ADMIN.deleteHome.useMutation({
        onSuccess: () => notifications.show({message: 'Success!'}),
        onError: (err) => notifications.show({message: err.message}),
        onSettled: () => utils.ADMIN.getHomesAggregateForUser.invalidate()
    })

    return (
        <SearchResultRow>
            {match(modal)
                .with(undefined, () => null)
                .with('set_demo', () => (
                    <SetDemoModal home={home} onClose={() => setModal(undefined)}/>
                ))
                .exhaustive()}
            <HStack>
                <IconHome size="2rem" color="var(--colors-gray-500)"/>
                <VStack>
                    <HStack>
                        <Link
                            preload={false}
                            to="/homes/$home_id"
                            params={{home_id: home.home_id}}
                        >
                            <TextLink>{buildShortAddress(home.address)}</TextLink>
                        </Link>
                    </HStack>
                    <CollapseToggle title="Assessments"
                                    onClick={toggle}
                                    isOpen={opened}
                    />
                </VStack>
                <VStack>
                    <Menu>
                        <Menu.Target>
                            <UnstyledButton>
                                <IconDots size="1.5rem"/>
                            </UnstyledButton>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item onClick={() => setModal('set_demo')}>Set as demo</Menu.Item>
                            <Menu.Item
                                onClick={() =>
                                    modals.openConfirmModal({
                                        title: 'Delete home',
                                        children: (
                                            <div>
                                                Are you sure you want to delete <br/>
                                                <strong>{buildShortAddress(home.address)}</strong> <br/> from{' '}
                                                <strong>{user?.name ?? 'unknown'}</strong>?
                                            </div>
                                        ),
                                        labels: {confirm: 'Confirm', cancel: 'Cancel'},
                                        onConfirm: async () => {
                                            await deleteHome.mutateAsync({home_id: home.home_id})
                                        },
                                    })
                                }
                            >
                                Delete Home
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </VStack>
            </HStack>
            <Collapse in={opened}>
                <VStack>
                    <VStack>
                        {home.assessments.map((assessment) => (
                            <AssessmentSummaryRow
                                key={assessment.assessment_id}
                                home={home}
                                assessment={assessment}
                                user={user}
                            />
                        ))}
                        {home.assessments.length === 0 && (
                            <TextBase>No assessments</TextBase>
                        )}
                    </VStack>
                </VStack>
            </Collapse>
        </SearchResultRow>
    )
}