import {
    timestamp,
    pgTable,
    primaryKey,
    varchar,
    text,
    json,
    pgEnum,
    jsonb,
    boolean, integer
} from 'drizzle-orm/pg-core'
import {
    HubspotLineItem, HubspotMeetingProperties,
} from './hubspot.js'
import {
    Address,
    AirSensorData, AssessmentFiles, AssessmentNote, AssessmentStatusSchema,
    EnergyDataRecord, FullAssessmentData,
    HomeDetails,
    RecData, SolarPotential
} from "./jsonb-schemas.js";


export const REC_STATUS_ENUM = pgEnum('recommendation_status', [
    'not_started',
    'in_progress',
    'done',
])
export const ACCESS_LEVEL_ENUM = pgEnum('access_level_enum', ['read', 'read-write'])

export const homes = pgTable('homes', {
    home_id: varchar('home_id', {length: 255}).notNull().primaryKey(),
    owner_user_id: varchar('owner_user_id', {length: 255}).notNull(),
    address: json('address').notNull().$type<Address>(),
    full_address_id: text('full_address_id').notNull(),
    // home_details: jsonb('home_details').notNull().$type<HomeDetailsDB>(),
    lat: varchar('lat', {length: 255}).notNull(),
    long: varchar('long', {length: 255}).notNull(),
    thumbnail_url: varchar('thumbnail_url'),
    aggregate_last_modified: integer('aggregate_last_modified')
})

export const shares = pgTable('shares', {
    home_id: varchar('home_id', {length: 255}).notNull(),
    verification_code: varchar('verification_code', {length: 255}).notNull(),
    verification_code_expires_at: timestamp('verification_code_expires_at').notNull(),
    target_email: varchar('target_email').notNull(),
    access_level: ACCESS_LEVEL_ENUM('access_level').notNull(),
    granted_user_id: varchar('granted_user_id', {length: 255}),
})

export const ASS_STATUS_ENUM = pgEnum('assessment_status', AssessmentStatusSchema.options)
export const assessments = pgTable('assessments', {
        assessment_id: varchar('assessment_id', {length: 255}).notNull(),
        home_id: varchar('home_id', {length: 255}).notNull(),
        hubspot_deal_id: varchar('hubspot_deal_id', {length: 255}),
        assessment_label: varchar('assessment_label', {length: 255}).notNull(),
        assessment_data: json('assessment_data').$type<FullAssessmentData>(),
        electric_usage: json('electric_usage').$type<Record<string,EnergyDataRecord>>(),
        gas_usage: json('gas_usage').$type<Record<string,EnergyDataRecord>>(),
        location_id: varchar('awair_location_id', {length: 255}),
        sensor_type: varchar('sensor_type', {length: 255}),
        last_analysis_run_at: timestamp('last_analysis_run_at'),
        created_at: timestamp('created_at').defaultNow().notNull(),
        home_details: jsonb('home_details').$type<HomeDetails>(),
        air_sensor_data: jsonb('air_sensor_data').$type<Record<string,AirSensorData>>(),
        // weather_data: jsonb('weather_data').$type(), //TODO add zod for this
        solar_data: jsonb('solar_data').$type<SolarPotential>(),
        assessment_files: jsonb('assessment_files').$type<AssessmentFiles>(),
        assessment_notes: jsonb('assessment_notes').$type<Record<string,AssessmentNote>>(),
        assessment_status: ASS_STATUS_ENUM('assessment_status'),
        assessment_meetings: jsonb('assessment_meetings').$type<Record<string,HubspotMeetingProperties>>()
    },
    (assess) => ({
        compoundKey: primaryKey({columns: [assess.home_id, assess.assessment_id]}),
    })
)

export const recommendations = pgTable(
    'recommendations',
    {
        home_id: varchar('home_id', {length: 255}).notNull(),
        recommendation_id: varchar('recommendation_id', {length: 255}).notNull(),
        assessment_id: varchar('assessment_id', {length: 255}),
        original_rec_id: varchar('original_rec_id', {length: 255}),
        rec_data: json('recommendation_data').$type<RecData>().notNull(),
        category: varchar('category', {length: 255}).notNull(),
        type: varchar('type', {length: 255}).notNull(),
        title: varchar('recommendation_title', {length: 255}).notNull(),
        description: text('description'),
        status: REC_STATUS_ENUM('status').notNull(),
        selected_quote_id: varchar('selected_quote_id', {length: 255}),
        hidden: boolean('hidden'),
        hubspot_line_items: jsonb('hubspot_line_items').$type<Array<HubspotLineItem>>(),
        hubspot_last_modified: varchar('hubspot_last_modified',{length:255}),
        hubspot_deal_id: varchar('hubspot_deal_id',{length:255}),
        hubspot_stage: varchar('hubspot_stage',{length:255})
    },
    (rec) => ({
        compoundKey: primaryKey({columns: [rec.home_id, rec.recommendation_id]}),
    })
)

export const quotes = pgTable(
    'quotes',
    {
        home_id: varchar('home_id', {length: 255}).notNull(),
        quote_id: varchar('quote_id', {length: 255}).notNull(),
        recommendation_id: varchar('recommendation_id', {length: 255}),
        original_rec_id: varchar('original_rec_id', {length: 255}).notNull(),
        quote_title: varchar('quote_title', {length: 255}).notNull(),
        quote_price: varchar('quote_price', {length: 255}).notNull(),
        quote_url: text('quote_url').notNull(),
        hubspot_quote_id: varchar('hubspot_quote_id',{length:255}),
        hubspot_quote_status: varchar('hubspot_quote_status',{length:255}),
        hubspot_last_modified: varchar('hubspot_last_modified',{length:255}),
        hubspot_line_items: jsonb('hubspot_line_items').$type<Array<HubspotLineItem>>(),
        hubspot_quote_amount: varchar('hubspot_quote_amount',{length:255})
    },
    (rec) => ({
        compoundKey: primaryKey({columns: [rec.home_id, rec.quote_id]}),
    })
)
