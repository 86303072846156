import {DesignedLink} from './DesignComponents.js';
import {COLOR_DARK_GREY, COLOR_WHITE, PRIMARY_LIGHT_BLUE} from './Theme.js';
import {Logo} from './Icons.js';
import React, {ReactElement, useContext} from "react";
import {Avatar, Burger, Menu, UnstyledButton} from "@mantine/core";
import {TextBase, TextXs, VStack} from './DesignBase.js';
import {Link, useNavigate} from "@tanstack/react-router";
import {isSiteAdmin} from "~/lib-client";
import {signOut} from "~/library-pages-auth";
import {UserDataContext} from "./UserDataProvider.js";
import classNames from "classnames";

function HeaderLink({text, to, active}: { text: string, to: string, active?: boolean }) {
    return <DesignedLink
        to={to}
        style={{
            borderRadius: "6.25rem",
            textDecoration: "none"
        }}
        inactiveProps={{
            style: {
                color: COLOR_DARK_GREY,
                backgroundColor: "transparent"
            }
        }}
        activeProps={{
            style: {
                color: COLOR_WHITE,
                backgroundColor: PRIMARY_LIGHT_BLUE
            }
        }}
    ><span className="mx-4 py-2 px-4 text-xl font-light">{text}</span></DesignedLink>
}


export function LoginAvatar() {
    const {user} = useContext(UserDataContext)
    const navigate = useNavigate()
    let component: ReactElement
    if (user) {
        component = <Menu width={180}>
            <Menu.Target>
                <UnstyledButton>
                    <VStack center>
                        <Avatar
                            src={user.image}
                            radius="xl"
                            imageProps={{referrerPolicy: 'no-referrer'}}
                        />
                        <TextBase m0>{user.name}</TextBase>
                        <TextXs light m0>{user.email}</TextXs>
                    </VStack>
                </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Item component={Link} to="/homes">
                    View Your Homes
                </Menu.Item>
                {isSiteAdmin(user) && (
                    <Menu.Item component={Link} to="/admin">
                        Admin
                    </Menu.Item>
                )}
                <Menu.Item component={Link} onClick={() => signOut({callbackUrl: '/'})}>
                    Sign Out
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    } else {
        component = <UnstyledButton onClick={async () => {
            await navigate({to: '/auth/signin', search:{redirect:"/homes"}})
        }}>
            <VStack center>
                <Avatar
                    src={null}
                    radius="xl"
                />
                <TextBase m0>Log In</TextBase>
            </VStack>
        </UnstyledButton>
    }
    return (
        <div className="ml-4 w-36 h-20 flex flex-row justify-center items-start">
            {component}
        </div>
    )
}


export function DesignedNavHeader() {
    const {user} = useContext(UserDataContext)
    const navigate = useNavigate()

    return <div className={classNames(
        "flex flex-row",
        "items-center justify-between",
        "w-full min-h-44 px-4 md:px-24"
    )}
                style={{
                    background: "rgba(246, 246, 246, 0.75)"
                }}>
        <DesignedLink to={"/"}><Logo/></DesignedLink>
        <div className="flex flex-row items-center justify-end">
            <div className="block sm:hidden">
                <Menu width={180}>
                    <Menu.Target>
                        <UnstyledButton>
                            <Burger />
                        </UnstyledButton>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Item component={Link} to="/blog">
                            Blog
                        </Menu.Item>
                        <Menu.Item component={Link} to="/about">
                            About
                        </Menu.Item>
                        {
                            user
                                ? <Menu.Item component={Link} onClick={() => signOut({callbackUrl: '/'})}>
                                    Sign Out ({user.email})
                                </Menu.Item>
                                : <Menu.Item component={Link} onClick={async () => {
                                    await navigate({to: '/auth/signin', search:{redirect:"/homes"}})
                                }}>
                                    Sign In
                                </Menu.Item>
                        }
                    </Menu.Dropdown>
                </Menu>
            </div>
            <div className="hidden sm:flex">
                <HeaderLink text="Blog" to="/blog"/>
                <HeaderLink text="About" to="/about"/>
            </div>
            <div className="hidden sm:flex">
                <LoginAvatar/>
            </div>
        </div>
    </div>
}