import {z} from "zod";
import {extendZodWithOpenApi} from "@asteasolutions/zod-to-openapi";

extendZodWithOpenApi(z);
export const ASSESSMENT_LABEL_BASELINE = "Baseline"
export const AssessmentStatusSchema = z.enum([
    'not_started', 'homeowner_providing_inputs', 'collecting_sensor_data', 'bim_team_analysis', 'internal_review', 'pending_homeowner_review', 'done'
])
export const HubspotEligibilitySurveySchema = z.object({
    email: z.string(),
    firstname: z.string(),
    lastname: z.string(),
    utility_providers: z.string().nullish(),
    address: z.string(),
    address_line_2: z.string().nullish(),
    city: z.string(),
    zip: z.string(),
    country: z.string().nullish(),
    state: z.string(),
    ownership: z.string().nullish(),
    priorities: z.string().nullish(),
    prior_assessment: z.string().nullish(),
    prior_assessment_date: z.string().nullish(),
    wall_insulation: z.string().nullish(),
    discounted_rate: z.string().nullish()
})
export type HubspotEligibilitySurveyData = z.infer<typeof HubspotEligibilitySurveySchema>
const currentYear = new Date().getFullYear()
export const BaseHomeDetailsSchema = z.object({
    beds: z.number().min(0).max(100).optional().describe("Beds"),
    sqft: z.number().min(0).optional(),
    baths: z.number().min(0).max(100).optional(),
    floors: z.number().min(0).max(10).optional(),
    yearBuilt: z.number().min(0).max(currentYear).optional(),
    fireplace: z.boolean().optional()
})
export type BaseHomeDetails = z.infer<typeof BaseHomeDetailsSchema>

export const HomeWalkthroughFilesSchema = z.object({
    window_image: z.string().url().optional()
})
export type HomeWalkthroughFiles = z.infer<typeof HomeWalkthroughFilesSchema>

export const HomeWalkthroughDetailsSchema = z.object({
    lightbulbs: z.array(z.enum(["Incandescent", "CFL", "Halogen", "LED"])).optional(),
    stove: z.enum(["Gas", "Electric", "Induction"]).optional(),
    thermostat: z.enum(["Smart", "Traditional"]).optional(),
    solar: z.boolean().optional(),
    solar_orientation: z.array(z.enum(["North", "Northeast", "East", "Southeast", "South", "Southwest", "West", "Northwest"])).optional(),
    solar_age: z.enum(["<5 Years", "5-10 Years", "10-15 Years", "15-20 Years", ">20 Years"]).optional(),
    solar_count: z.number().optional(),
    solar_capacity: z.number().optional(),
    battery: z.boolean().optional(),
    battery_capacity: z.number().optional(),
})
export type HomeWalkthroughDetails = z.infer<typeof HomeWalkthroughDetailsSchema>

export const HomeWalkthroughSurveyResultsSchema =
    HomeWalkthroughDetailsSchema.merge(HomeWalkthroughFilesSchema)
export type HomeWalkthroughSurveyResults = z.infer<typeof HomeWalkthroughSurveyResultsSchema>


export const HeatingAndCoolingWalkthroughFilesSchema = z.object({
    insulation_image: z.string().url().optional(),
    crawlspace_image: z.string().url().optional(),
    primary_cooling_image: z.string().url().optional(),
    secondary_cooling_image: z.string().url().optional(),
    primary_heating_image: z.string().url().optional(),
    secondary_heating_image: z.string().url().optional(),
    water_heater_image: z.string().url().optional(),
})
export const UtilityAgeSchema = z.enum(["<5 Years", "5-10 Years", "10-15 Years", "15-20 Years", ">20 Years", "Not Applicable"])
export type UtilityAge = z.infer<typeof UtilityAgeSchema>
export type HeatingAndCoolingWalkthroughFiles = z.infer<typeof HeatingAndCoolingWalkthroughFilesSchema>
export const HeatingAndCoolingWalkthroughDetailsSchema = z.object({
    attic: z.boolean().optional(),
    attic_accessible: z.boolean().optional(),
    crawlspace: z.boolean().optional(),
    primary_cooling: z.enum(["Central air conditioner", "Room air conditioner", "Electric heat pump",
        "Minisplit (ductless) heat pump", "Ground coupled heat pump", "Direct evaporative cooling", "None"]).optional(),
    primary_cooling_age: UtilityAgeSchema.optional(),
    primary_cooling_merv: z.enum(["MERV 8", "MERV 9", "MERV 10", "MERV 11", "MERV 12", "MERV 13", "MERV 14", "HEPA", "None", "Not Applicable"]).optional(),
    secondary_cooling: z.enum(["Central air conditioner", "Room air conditioner", "Electric heat pump",
        "Minisplit (ductless) heat pump", "Ground coupled heat pump", "Direct evaporative cooling", "None"]).optional(),
    secondary_cooling_age: UtilityAgeSchema.optional(),
    secondary_cooling_merv: z.enum(["MERV 8", "MERV 9", "MERV 10", "MERV 11", "MERV 12", "MERV 13", "MERV 14", "HEPA", "None", "Not Applicable"]).optional(),
    electric_panel: z.enum(["100amp", "200amp", "Other"]).optional(),
    primary_heating: z.enum(["Central gas furnace", "Room (through-the-wall) gas furnace",
        "Gas boiler", "Propane (LPG) central furnace", "Propane (LPG) wall furnace / Propane (LPG) boiler",
        "Oil furnace", "Oil Boiler", "Electric furnace", "Electric heat pump", "Electric baseboard heater",
        "Ground coupled heat pump", "Minisplit (ductless) heat pump", "Electric boiler", "Wood Stove", "Pellet Stove", "None"]).optional(),
    primary_heating_age: UtilityAgeSchema.optional(),
    secondary_heating: z.enum(["Central gas furnace", "Room (through-the-wall) gas furnace",
        "Gas boiler", "Propane (LPG) central furnace", "Propane (LPG) wall furnace / Propane (LPG) boiler",
        "Oil furnace", "Oil Boiler", "Electric furnace", "Electric heat pump", "Electric baseboard heater",
        "Ground coupled heat pump", "Minisplit (ductless) heat pump", "Electric boiler", "Wood Stove", "Pellet Stove", "None"]).optional(),
    secondary_heating_age: UtilityAgeSchema.optional(),
    water_heater_age: UtilityAgeSchema.optional()
})
export type HeatingAndCoolingWalkthroughDetails = z.infer<typeof HeatingAndCoolingWalkthroughDetailsSchema>

export const HeatingAndCoolingWalkthroughSurveyResultsSchema =
    HeatingAndCoolingWalkthroughDetailsSchema.merge(HeatingAndCoolingWalkthroughFilesSchema)

export const DerivedHomeDetailsSchema = z.object({
    //window_image
    window_type: z.enum(["Single Pane","Double Pane","Triple Pane"]).optional(),
    //insulation_image

    insulation_type: z.enum([
        "Cellulose, loose fill",
        "Cellulose, high density",
        "Fiberglass, batts",
        "Fiberglass, loose fill",
        "Fiberglass, loose fill, fluffed below manufacturer's standards",
        "Rockwool",
        "Vermiculite",
        "Poly-isocyanurate, rigid board",
        "Polystyrene, expanded rigid board",
        "Polystyrene, extruded rigid board",
        "Low Density Urethane, sprayed foam",
        "Urethane, sprayed foam",
        "Urea Formaldehyde Foam"
    ]).optional(),
    insulation_depth: z.number().optional(),
    //primary_cooling_image
    primary_cooling_btus: z.number().optional(),
    primary_cooling_seer: z.number().optional(),
    //secondary_cooling_image
    secondary_cooling_btus: z.number().optional(),
    secondary_cooling_seer: z.number().optional(),
    //primary_heating_image
    primary_heating_btus: z.number().optional(),
    primary_heating_afue: z.number().optional(),
    //secondary_heating_image
    secondary_heating_btus: z.number().optional(),
    secondary_heating_afue: z.number().optional(),
    //water_heater_image
    water_heater_btus:z.number().optional(),
    water_heater_afue:z.number().optional()
})

export const HomeDetailsSchema = BaseHomeDetailsSchema
        .merge(HomeWalkthroughDetailsSchema)
        .merge(HeatingAndCoolingWalkthroughDetailsSchema)
    .merge(DerivedHomeDetailsSchema)
export type HomeDetails = z.infer<typeof HomeDetailsSchema>

export const TypeFormHomeDetailsSchema =
    HomeWalkthroughDetailsSchema
        .merge(HomeWalkthroughFilesSchema)
        .merge(HeatingAndCoolingWalkthroughDetailsSchema)
        .merge(HeatingAndCoolingWalkthroughFilesSchema)
export const PartialTypeFormHomeDetailsSchema = TypeFormHomeDetailsSchema
    .partial()
export type PartialTypeFormHomeDetails = z.infer<typeof PartialTypeFormHomeDetailsSchema>
export const PartialHomeDetailsDBSchema = HomeDetailsSchema.partial()
export type PartialHomeDetailsDB = z.infer<typeof PartialHomeDetailsDBSchema>

// Mapping a zod Object into a zod union schema with objects of the original schema
export function mapZodObjectToZodUnion(homeDetails: z.ZodObject<any>) {
    const zodForm: z.ZodTypeAny[] = []
    for (const field in homeDetails.shape) {
        zodForm.push(
            z.object({
                [field]: homeDetails.shape[field],
            }).describe(field)
        )
    }

    return z.object({
        Details: z.union(zodForm as [z.ZodTypeAny, z.ZodTypeAny, ...z.ZodTypeAny[]]).optional()
    })
}


export const RRecommendationSchema = z.object({
    ID: z.number(),
    Upfront_Cost_Low: z.number(),
    Upfront_Cost_High: z.number(),
    Net_Cost_Low: z.number(),
    Net_Cost_High: z.number(),
    Annual_Savings_Low: z.number(),
    Annual_Savings_High: z.number(),
})
export const AnalysisDataValueSchema = z.object({
    key: z.string(),
    co2: z.number().nullable(),
    pm25: z.number().nullable(),
    voc: z.number().nullable(),
    temp: z.number().nullable(),
    rh: z.number().nullable(),
    co2_threshold: z.number().nullable(),
    pm25_threshold: z.number().nullable(),
    voc_threshold: z.number().nullable(),
    temp_threshold: z.number().nullable(),
    rh_threshold: z.number().nullable(),
    aer: z.number().nullable(),
    rvalue: z.number().nullable(),
})
export type AnalysisDataValue = z.infer<typeof AnalysisDataValueSchema>
export const AnalysisDataSchema = z.record(
    z.string(),
    AnalysisDataValueSchema
)
export type AnalysisData = z.infer<typeof AnalysisDataSchema>

export const ElectrificationDataSchema = z.object({
    energy_use: z.object({Electric: z.number(), Gas: z.number()}),
    energy_cost: z.object({Electric: z.number(), Gas: z.number()}),
    energy_chart: z.array(
        z.object({
            Date: z.coerce.date(),
            Baseload_Usage: z.number(),
            Heating_Usage: z.number(),
            Cooling_Usage: z.number(),
        })
    ),
    hvac_sizing: z.object({
        heating_load_current: z.number().nullish(),
        cooling_load_current: z.number().nullish(),
        // heating
        heating_load: z.number().nullish(),
        heating_infiltration: z.number().nullish(),
        heating_insulation: z.number().nullish(),
        heating_radiation: z.number().nullish(),
        // cooling
        cooling_load: z.number().nullish(),
        cooling_infiltration: z.number().nullish(),
        cooling_insulation: z.number().nullish(),
        cooling_radiation: z.number().nullish(),
    }),
})
export type ElectrificationData = z.infer<typeof ElectrificationDataSchema>
export const FullAssessmentSchema = z.object({
    analysis: AnalysisDataSchema.optional(),
    electrification: ElectrificationDataSchema.optional(),
})
export type FullAssessmentData = z.infer<typeof FullAssessmentSchema>

export const AssessmentFileSchema = z.object({
    name: z.string(),
    s3_url: z.string().url(),
    created_by: z.string(),
    created_date: z.string().datetime()
})

export const AssessmentFilesEnumSchema = z.enum([
    'window_image', //Home Walkthrough images
    'insulation_image','crawlspace_image', 'primary_cooling_image', 'secondary_cooling_image', 'primary_heating_image', 'secondary_heating_image','water_heater_image', //HVAC Walkthrough images
    'capture_video', 'capture_rendering_raw', 'capture_rendering_cleaned', 'energy_model_geometry', 'energy_model_idf', 'capture_rendering_enhanced'
])
export type AssessmentFilesEnum = z.infer<typeof AssessmentFilesEnumSchema>
export const AssessmentFilesSchema = z.record(AssessmentFilesEnumSchema, z.array(AssessmentFileSchema).optional())

export type AssessmentFiles = z.infer<typeof AssessmentFilesSchema>
export type AssessmentFile = z.infer<typeof AssessmentFileSchema>
export const AssessmentNoteSchema = z.object({
    message: z.string(),
    created_by: z.string(),
    created_date: z.string().datetime()
})
export type AssessmentNote = z.infer<typeof AssessmentNoteSchema>


const SolarCostSchema = z.object({
    currencyCode: z.string(),
    units: z.string().optional(),
    nanos: z.number().optional()
})
const SolarSavingsSchema = z.object({
    savingsYear1: SolarCostSchema,
    savingsYear20: SolarCostSchema,
    presentValueOfSavingsYear20: SolarCostSchema,
    financiallyViable: z.boolean(),
    savingsLifetime: SolarCostSchema,
    presentValueOfSavingsLifetime: SolarCostSchema
})

/*this is just a partial schema confiriming the data that the rscript currently uses*/
export const SolarPotentialSchema = z.object({
    maxArrayPanelsCount: z.number(),
    maxArrayAreaMeters2: z.number(),
    maxSunshineHoursPerYear: z.number(),
    carbonOffsetFactorKgPerMwh: z.number(),
    wholeRoofStats: z.object({}),
    financialAnalyses: z.array(z.object({
        monthlyBill: SolarCostSchema.optional(),
        panelConfigIndex: z.number(),
        financialDetails: z.object({
            initialAcKwhPerYear: z.number().optional(),
            remainingLifetimeUtilityBill: SolarCostSchema.optional(),
            federalIncentive: SolarCostSchema.optional(),
            stateIncentive: SolarCostSchema.optional(),
            utilityIncentive: SolarCostSchema.optional(),
            lifetimeSrecTotal: SolarCostSchema.optional(),
            costOfElectricityWithoutSolar: SolarCostSchema.optional(),
            netMeteringAllowed: z.boolean().optional(),
            solarPercentage: z.number().optional(),
            percentageExportedToGrid: z.number().optional()
        }).optional(),
        leasingSavings: z.object({
            leasesAllowed: z.boolean().optional(),
            leasesSupported: z.boolean().optional(),
            annualLeasingCost: SolarCostSchema.optional(),
            savings: SolarSavingsSchema.optional()
        }).optional(),
        cashPurchaseSavings: z.object({
            outOfPocketCost: SolarCostSchema,
            upfrontCost: SolarCostSchema,
            rebateValue: SolarCostSchema,
            paybackYears: z.number(),
            savings: SolarSavingsSchema
        }).optional(),
        financedPurchaseSavings: z.object({
            annualLoanPayment: SolarCostSchema.optional(),
            rebateValue: SolarCostSchema.optional(),
            loanInterestRate: z.number().optional(),
            savings: SolarSavingsSchema.optional()
        }).optional()
    })),
    solarPanelConfigs: z.array(z.object({
        panelsCount: z.number().optional(),
        yearlyEnergyDcKwh: z.number().optional(),
        roofSegmentSummaries: z.array(z.object({
            pitchDegrees: z.number(),
            azimuthDegrees: z.number(),
            panelsCount: z.number(),
            yearlyEnergyDcKwh: z.number(),
            segmentIndex: z.number()
        })).optional()
    }))
})
export type SolarPotential = z.infer<typeof SolarPotentialSchema>
export const AirSensorDataSchema = z.object({
    device_id: z.string(),
    datetime: z.string().datetime(),
    datetime_local: z.string().datetime(),
    hour: z.number(),
    sensors_temp: z.number(),
    sensors_radon: z.number().optional(),
    sensors_pressure: z.number().optional(),
    sensors_pm25: z.number().optional(),
    sensors_voc: z.number().optional(),
    sensors_lux: z.number().optional(),
    sensors_co2: z.number(),
    sensors_humid: z.number().optional(),
})
export type AirSensorData = z.infer<typeof AirSensorDataSchema>
export const RecDataSchema = RRecommendationSchema
    .omit({ID: true})
    .merge(z.object({Prerequisites: z.array(z.string()).optional()}))

export type RecData = z.infer<typeof RecDataSchema>


export const EnergyDataRecordSchema = z.object({
    date: z.string()/*.date()*/,//TODO rename to end_date
    num_days: z.number().optional(),//TODO change to not optional
    usage: z.number(),
    cost: z.number(),
})

export type EnergyDataRecord = z.infer<typeof EnergyDataRecordSchema>
export const AssessmentRunResultSchema = z.array(z.string())
export type AssessmentRunResult = z.infer<typeof AssessmentRunResultSchema>
export const AddressSchema = z.object({
    address1: z.string(),
    address2: z.string().nullish(),
    neighborhood: z.string().nullish(),
    city: z.string(),
    county: z.string().nullish(),
    state: z.string(),
    countryCode: z.string(),
    postalCode: z.string(),
}).openapi("Address")
export type Address = z.infer<typeof AddressSchema>
