import {Home} from "@seeair/schemas";
import {Button, InputError, Loader, Modal, ModalProps, TextInput} from "@mantine/core";
import {useForm} from "@mantine/form";
import {trpc} from "~/lib-client";
import {notifications} from "@mantine/notifications";




export function SetDemoModal(props: {home: Home} & Omit<ModalProps, 'opened'>) {
    const form = useForm({initialValues: {demo_suffix: ''}})

    const utils = trpc.useUtils()
    const setAsDemo = trpc.ADMIN.setAsDemo.useMutation({
        onSuccess: async () => {
            notifications.show({message: 'Success!'})
            props.onClose()
        },
    })
    return (
        <Modal closeOnClickOutside={false} opened title="Set as demo" {...props}>
            <form
                onSubmit={form.onSubmit((values) => {
                    setAsDemo.mutate({...values, home_id: props.home.home_id})
                })}
            >
                <TextInput label="Demo suffix" {...form.getInputProps('demo_suffix')} />
                <Button
                    onClick={() => {}}
                >
                    Submit{setAsDemo.isPending && <Loader size="1rem" />}
                </Button>
            </form>
            {setAsDemo.error && <InputError>{setAsDemo.error.message}</InputError>}
        </Modal>
    )
}