import {HomeAggregate} from "@seeair/schemas";
import {Button, InputError, Loader, Select, TextInput, Tooltip} from "@mantine/core";
import {trpc} from "~/lib-client";
import {notifications} from "@mantine/notifications";
import {useForm, zodResolver} from "@mantine/form";
import {z} from "zod";
import {UserBadge, DeleteButton, HStack, TextLg, TextSmItalic, VStack, TextBase} from "@seeair/shared-components";
import dayjs from "dayjs";
import {IconExclamationCircle} from "@tabler/icons-react";

export function SharePanel({home}: { home: HomeAggregate }) {
    const utils = trpc.useUtils()
    const shares = trpc.HOMEOWNER.getShares.useQuery({
        home_id: home.home_id,
    })
    const addShare = trpc.HOMEOWNER.addShare.useMutation({
        onSuccess: () => {
            notifications.show({message: 'Success!'})
            form.reset()
        },
        onSettled: () => Promise.allSettled([utils.HOMEOWNER.getShares.refetch()]),
    })
    const updateShare = trpc.HOMEOWNER.updateShare.useMutation({
        onSuccess: () => {
            notifications.show({message: 'Success!'})
        },
        onSettled: () => Promise.allSettled([utils.HOMEOWNER.getShares.refetch()]),
    })
    const removeShare = trpc.HOMEOWNER.removeShare.useMutation({
        onSuccess: () => {
            notifications.show({message: 'Success!'})
        },
        onSettled: () => Promise.allSettled([utils.HOMEOWNER.getShares.refetch()]),
    })

    const form = useForm({
        initialValues: {
            target_email: '',
            access_level: 'read-write' as 'read' | 'read-write',
        },
        validate: zodResolver(
            z.object({
                target_email: z.string().email(),
                access_level: z.enum(['read', 'read-write']),
            })
        ),
    })

    const readWriteProps = {
        className: "w-32",
        classNames: {dropdown: "w-40"},
        data: [
            {label: 'Read', value: 'read'},
            {label: 'Read / Write', value: 'read-write'},
        ],
    } as const

    if (home.permissions && !home.permissions.has('owner')) {
        // Should never get here, just in case.
        return (
            <VStack>
                <TextBase>You do not have permission to share this home, please ask the owner to do so.</TextBase>
            </VStack>
        )
    }

    return (
        <VStack>
            <TextLg>People with access</TextLg>
            {shares.isLoading ? (
                <Loader size="1.5rem"/>
            ) : !shares.data || shares.data.length === 0 ? (
                'You have not shared with anybody yet'
            ) : (
                <VStack>
                    {shares.data.map((share) => {
                        const isExpiredIfNotGranted = dayjs(
                            share.verification_code_expires_at
                        ).isBefore()
                        return (
                            <HStack key={share.verification_code}>
                                {share.granted_user ? (
                                    <UserBadge user={share.granted_user}/>
                                ) : (
                                    <HStack>
                                        <Tooltip
                                            label={isExpiredIfNotGranted ? 'Invite expired' : 'Invite pending'}
                                        >
                                            <IconExclamationCircle/>
                                        </Tooltip>
                                        <TextSmItalic>{share.target_email}</TextSmItalic>
                                    </HStack>
                                )}
                                <Select
                                    {...readWriteProps}
                                    value={share.access_level}
                                    onChange={(val) => {
                                        if (!val) {
                                            return
                                        }
                                        if (!(val === 'read' || val === 'read-write')) {
                                            throw new Error('Invalid access level')
                                        }
                                        updateShare.mutate({
                                            home_id: share.home_id,
                                            target_email: share.target_email,
                                            access_level: val,
                                        })
                                    }}
                                />
                                <DeleteButton
                                    isPending={removeShare.isPending && removeShare.variables.target_email === share.target_email}
                                    onClick={() =>
                                        removeShare.mutate({
                                            home_id: share.home_id,
                                            target_email: share.target_email,
                                        })
                                    }
                                />
                            </HStack>
                        )
                    })}
                </VStack>
            )}
            <TextLg>Send invite</TextLg>
            <form
                onSubmit={form.onSubmit((values) => {
                    addShare.mutate({home_id: home.home_id, ...values})
                })}
            >
                <HStack>
                    <TextInput
                        label="Email"
                        {...form.getInputProps('target_email')}
                    />
                    <Select
                        label="Access"
                        {...readWriteProps}
                        {...form.getInputProps('access_level')}
                    />
                </HStack>
                <Button type="submit">
                    Share&nbsp;{addShare.isPending && <Loader size="1rem"/>}
                </Button>
                {addShare.error && (
                    <InputError>{addShare.error.message}</InputError>
                )}
            </form>
        </VStack>
    )
}
