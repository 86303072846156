import React from "react";
import {COLOR_DARK_GREY, COLOR_WHITE, PRIMARY_BLUE, PRIMARY_LIGHT_BLUE} from './Theme.js';

export function Logo({inverse}: { inverse?: boolean }) {
    return <div className="w-52 h-16">
        <svg xmlns="http://www.w3.org/2000/svg" width="210" height="65" viewBox="0 0 210 65" fill="none">
            <g clipPath="url(#clip0_304_35629)">
                <path
                    d="M72.2985 46.2166C69.9679 44.6008 66.9284 43.7538 63.9611 42.9249C61.234 42.156 58.8372 41.4592 57.1553 40.2699C55.4734 39.0745 54.4703 37.4286 54.4642 34.6054C54.4642 32.2447 55.161 30.3526 56.5967 29.0311C58.0383 27.7096 60.2608 26.9467 63.3664 26.9467C66.7122 26.9467 69.5594 27.7756 71.2714 29.4335C71.4516 29.6077 71.6498 29.812 71.8961 29.9802C72.1424 30.1423 72.4427 30.2685 72.7911 30.2685C73.1876 30.2685 73.536 30.1483 73.7762 29.9081C74.0225 29.6678 74.1366 29.3194 74.1366 28.9169C74.1366 28.3643 73.8543 27.8898 73.4218 27.4573V27.4513C71.0551 25.3309 67.451 24.1355 63.3724 24.1355C59.6001 24.1355 56.6387 25.1086 54.6144 26.9107C52.5901 28.7127 51.5329 31.3377 51.5329 34.5514C51.5329 38.3417 53.0406 40.7925 55.2451 42.4263C57.4496 44.0662 60.3149 44.9252 63.0781 45.67C65.8352 46.4089 68.4121 47.0936 70.2682 48.301C72.1244 49.5144 73.2777 51.2083 73.2837 54.1036C73.2837 56.5124 72.4607 58.5126 70.8089 59.9242C69.151 61.3358 66.6281 62.1768 63.1982 62.1768C58.621 62.1768 55.4434 60.7712 53.5332 58.3505C53.347 58.1042 53.1487 57.8699 52.8965 57.6837C52.6442 57.4975 52.3438 57.3713 51.9954 57.3713C51.593 57.3713 51.2386 57.5095 51.0043 57.7678C50.764 58.0261 50.6499 58.3805 50.6499 58.7769C50.6499 59.2575 50.8541 59.72 51.1785 60.1044C53.7735 63.246 57.8821 64.988 63.3243 64.988C67.5411 64.988 70.7728 63.8527 72.9473 61.9065C75.1278 59.9663 76.233 57.2272 76.233 54.0916C76.239 50.2352 74.6472 47.8145 72.3166 46.2046L72.2985 46.2166Z"
                    fill={inverse ? COLOR_WHITE : PRIMARY_BLUE}/>
                <path
                    d="M105.396 61.8764C105.102 61.6182 104.693 61.474 104.207 61.474H85.1709V45.2075H97.569C98.0556 45.2075 98.4701 45.0573 98.7644 44.805C99.0587 44.5527 99.2209 44.1863 99.2209 43.8019C99.2209 43.4174 99.0587 43.051 98.7644 42.7987C98.4701 42.5404 98.0616 42.3963 97.569 42.3963H85.1709V27.6735H103.852C104.339 27.6735 104.753 27.5233 105.042 27.271C105.336 27.0188 105.498 26.6523 105.498 26.2679C105.498 25.8835 105.336 25.5171 105.042 25.2648C104.747 25.0065 104.339 24.8623 103.852 24.8623H83.6993C83.2668 24.8623 82.8943 25.0005 82.63 25.2588C82.3657 25.5231 82.2276 25.9015 82.2336 26.328V62.8375C82.2336 63.27 82.3657 63.6425 82.63 63.9068C82.8943 64.1711 83.2668 64.3092 83.6993 64.3032H104.207C104.693 64.3032 105.108 64.153 105.396 63.9007C105.69 63.6485 105.852 63.282 105.852 62.8976C105.852 62.5132 105.69 62.1468 105.396 61.8945V61.8764Z"
                    fill={inverse ? COLOR_WHITE : PRIMARY_BLUE}/>
                <path
                    d="M134.379 61.8764C134.085 61.6182 133.676 61.474 133.184 61.474H114.148V45.2075H126.546C127.033 45.2075 127.447 45.0573 127.741 44.805C128.036 44.5527 128.198 44.1863 128.198 43.8019C128.198 43.4174 128.036 43.051 127.741 42.7987C127.453 42.5404 127.039 42.3963 126.546 42.3963H114.148V27.6735H132.829C133.316 27.6735 133.73 27.5233 134.025 27.271C134.319 27.0188 134.481 26.6523 134.481 26.2679C134.481 25.8835 134.319 25.5171 134.025 25.2648C133.73 25.0065 133.322 24.8623 132.829 24.8623H112.676C112.244 24.8623 111.871 25.0005 111.607 25.2588C111.343 25.5231 111.205 25.9015 111.211 26.328V62.8375C111.211 63.27 111.343 63.6425 111.607 63.9068C111.871 64.1711 112.244 64.3092 112.676 64.3032H133.184C133.67 64.3032 134.085 64.153 134.379 63.9007C134.673 63.6485 134.836 63.282 134.836 62.8976C134.836 62.5132 134.673 62.1468 134.379 61.8945V61.8764Z"
                    fill={inverse ? COLOR_WHITE : PRIMARY_BLUE}/>
                <path
                    d="M167.176 62.1588L153.661 25.7033C153.409 24.9404 152.856 24.4298 152.147 24.4298C151.438 24.4298 150.886 24.9404 150.633 25.7033L137.118 62.1588C136.992 62.5372 136.932 62.9096 136.926 63.24C136.926 63.6364 137.046 63.9969 137.28 64.2491C137.52 64.5074 137.869 64.6456 138.271 64.6456C138.674 64.6456 139.01 64.5014 139.25 64.2732C139.497 64.0449 139.653 63.7506 139.779 63.4382L143.635 52.9683H160.629L164.485 63.4322C164.611 63.7446 164.773 64.0449 165.014 64.2732C165.254 64.5014 165.59 64.6456 165.993 64.6456C166.395 64.6456 166.75 64.5074 166.984 64.2491C167.224 63.9908 167.338 63.6364 167.338 63.24C167.338 62.9156 167.272 62.5372 167.146 62.1588H167.176ZM159.59 50.1511H144.705L152.147 29.9261L159.59 50.1511Z"
                    fill={inverse ? COLOR_WHITE : PRIMARY_LIGHT_BLUE}/>
                <path
                    d="M174.895 24.9764C174.637 24.6701 174.252 24.4899 173.838 24.4899C173.417 24.4899 173.039 24.6701 172.781 24.9764C172.516 25.2828 172.372 25.7093 172.372 26.1958V62.9396C172.372 63.4262 172.516 63.8527 172.781 64.159C173.039 64.4654 173.423 64.6456 173.838 64.6456C174.258 64.6456 174.637 64.4654 174.895 64.159C175.159 63.8527 175.303 63.4262 175.303 62.9396V26.1958C175.303 25.7093 175.159 25.2828 174.895 24.9764Z"
                    fill={inverse ? COLOR_WHITE : PRIMARY_LIGHT_BLUE}/>
                <path
                    d="M209.297 61.9365L200.497 46.7272C203.41 46.0965 205.632 44.7569 207.128 42.9248C208.678 41.0267 209.447 38.6179 209.447 35.9689C209.447 32.7192 208.299 29.92 206.161 27.9498C204.023 25.9796 200.911 24.8383 197.019 24.8383H185.047C184.614 24.8383 184.242 24.9764 183.978 25.2347C183.713 25.499 183.575 25.8774 183.581 26.3039V62.9276C183.581 63.4142 183.725 63.8407 183.99 64.147C184.248 64.4534 184.632 64.6336 185.047 64.6336C185.467 64.6336 185.846 64.4534 186.104 64.147C186.368 63.8407 186.513 63.4142 186.513 62.9276V47.0936H197.361L206.78 63.4562C206.966 63.7686 207.17 64.0569 207.44 64.2732C207.711 64.4954 208.047 64.6336 208.456 64.6336C208.858 64.6336 209.2 64.4954 209.423 64.2371C209.645 63.9848 209.747 63.6484 209.747 63.282C209.747 62.8015 209.549 62.357 209.303 61.9245L209.297 61.9365ZM206.503 35.9749C206.503 38.5038 205.68 40.5702 204.095 42.0118C202.509 43.4535 200.142 44.2884 197.007 44.2884H186.501V27.6675H197.007C200.166 27.6675 202.539 28.5325 204.113 29.9861C205.692 31.4458 206.497 33.5061 206.497 35.9749H206.503Z"
                    fill={inverse ? COLOR_WHITE : PRIMARY_LIGHT_BLUE}/>
                <path
                    d="M53.497 15.7019C52.9084 15.1192 51.9593 15.1252 51.3766 15.7139L22.8502 44.5107L15.1854 37.0922C14.5908 36.5156 13.6417 36.5336 13.065 37.1283C12.4884 37.723 12.5064 38.672 13.1011 39.2487L21.5407 47.418C21.9071 47.7724 22.3936 47.9586 22.8682 47.9526C23.3547 47.9526 23.8533 47.7604 24.2197 47.388L53.5091 17.8223C54.0917 17.2336 54.0857 16.2845 53.497 15.7019Z"
                    fill={inverse ? COLOR_WHITE : PRIMARY_LIGHT_BLUE}/>
                <path
                    d="M40.6122 61.7323H3.52601C3.23168 61.7323 2.99741 61.492 2.99741 61.2037V24.6761L25.0485 3.1656L39.561 17.534C40.1497 18.1166 41.0988 18.1106 41.6814 17.5219C42.2641 16.9333 42.2581 15.9842 41.6694 15.4015L26.8566 0.744848C26.364 0.252287 25.6973 0 25.0485 0C24.4058 0 23.745 0.24628 23.2525 0.732834L0.450513 22.9702C0.162185 23.2525 0 23.6369 0 24.0454V61.2037C0 63.1499 1.5798 64.7297 3.52601 64.7297H40.6122C41.4412 64.7297 42.1079 64.0569 42.1079 63.234C42.1079 62.4111 41.4352 61.7383 40.6122 61.7383V61.7323Z"
                    fill={inverse ? COLOR_WHITE : PRIMARY_BLUE}/>
            </g>
            <defs>
                <clipPath id="clip0_304_35629">
                    <rect width="209.741" height="65" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    </div>
}

export function IconDollar() {
    return <div className="w-28">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 123 77" fill="none">
            <path d="M121.706 75.6949H2.58984V2.61017H121.706V41.7627" fill="url(#paint0_linear_540_19219)"/>
            <path d="M121.706 75.6949H2.58984V2.61017H121.706V41.7627" stroke="#3B444B" strokeWidth="2.5"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M100.989 2.61017C100.989 14.1471 110.26 23.4915 121.705 23.4915" stroke="#3B444B" strokeWidth="2.5"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.58984 23.4915C14.0353 23.4915 23.3056 14.1471 23.3056 2.61017" stroke="#3B444B"
                  strokeWidth="2.5"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.58984 54.8136C14.0353 54.8136 23.3056 64.158 23.3056 75.6949" stroke="#3B444B" strokeWidth="2.5"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M62.1478 60.0339C72.1587 60.0339 80.2741 51.8536 80.2741 41.7627C80.2741 31.6718 72.1587 23.4915 62.1478 23.4915C52.1369 23.4915 44.0215 31.6718 44.0215 41.7627C44.0215 51.8536 52.1369 60.0339 62.1478 60.0339Z"
                stroke="#3B444B" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <defs>
                <linearGradient id="paint0_linear_540_19219" x1="95.8109" y1="19.5763" x2="4.80548" y2="73.1017"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="white"/>
                    <stop offset="1" stopColor="#A9F387" stopOpacity="0.3"/>
                </linearGradient>
            </defs>
        </svg>
    </div>
}


export function IconCouch() {
    return <div className="w-28">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 145 78" fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M123.383 70.2745H20.6175C20.55 70.491 20.4813 70.7073 20.4115 70.9234C19.6308 73.3813 17.2886 74.94 14.7062 74.94H9.18107V75C5.27745 75 2.39478 71.2831 3.41572 67.5062C6.23834 56.6553 6.23834 45.3247 3.41572 34.4738C2.45483 30.637 5.27745 26.98 9.18107 26.98H14.7062C17.2285 26.98 19.6308 28.5387 20.4115 30.9967C20.4395 31.083 20.4673 31.1693 20.495 31.2557H20.772V14.99C20.772 8.3955 26.1771 3 32.7832 3H110.856C117.462 3 122.867 8.3955 122.867 14.99V31.2557H123.505C123.533 31.1693 123.561 31.083 123.589 30.9967C124.369 28.5387 126.772 26.98 129.294 26.98H134.819C138.723 26.98 141.545 30.637 140.585 34.4738C137.762 45.3247 137.762 56.6553 140.585 67.5062C141.605 71.2831 138.723 75 134.819 75V74.94H129.294C126.712 74.94 124.369 73.3813 123.589 70.9234C123.519 70.7073 123.45 70.491 123.383 70.2745Z"
                  fill="url(#paint0_linear_540_19226)" fillOpacity="0.5"/>
            <path
                d="M21.1162 31.2793V14.8881C21.1162 8.56172 26.5484 3.38556 33.1876 3.38556H111.652C115.454 3.38556 118.834 5.05343 121.068 7.69903"
                stroke="#3B444B" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M135.989 75.8467H130.258C127.579 75.8467 125.149 74.2169 124.339 71.6469C119.978 58.0448 119.978 43.4397 124.339 29.9003C125.149 27.3303 127.641 25.7006 130.258 25.7006H135.989C140.039 25.7006 142.967 29.5242 141.97 33.5359C139.042 44.8814 139.042 56.7285 141.97 68.074C143.029 72.023 140.039 75.9094 135.989 75.9094V75.8467Z"
                stroke="#3B444B" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M9.01117 75.8467H14.7427C17.4216 75.8467 19.8513 74.2169 20.6612 71.6469C25.0222 58.0448 25.0222 43.4397 20.6612 29.9003C19.8513 27.3303 17.3593 25.7006 14.7427 25.7006H9.01117C4.96169 25.7006 2.0336 29.5242 3.0304 33.5359C5.95848 44.8814 5.95848 56.7285 3.0304 68.074C1.97131 72.023 4.96169 75.9094 9.01117 75.9094V75.8467Z"
                stroke="#3B444B" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M122.476 68.9359L22.5244 68.9359" stroke="#3B444B" strokeWidth="2.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M121.068 50.805C89.6129 39.6475 55.3863 39.6475 23.9316 50.805" stroke="#3B444B" strokeWidth="2.5"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <defs>
                <linearGradient id="paint0_linear_540_19226" x1="119.381" y1="8.26256" x2="7.82139" y2="72.9307"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="white"/>
                    <stop offset="1" stopColor="#A9F387"/>
                </linearGradient>
            </defs>
        </svg>
    </div>
}

export function IconHome() {
    return <div className="w-28">
        <svg xmlns="http://www.w3.org/2000/svg" width="114" height="115" viewBox="0 0 114 115" fill="none">
            <path d="M93.9532 57.0208V112.604H2.41504V32.775L48.16 2.39584L74.7278 20.7479L84.3888 27.4083"
                  fill="url(#paint0_linear_818_385)" fillOpacity="0.5"/>
            <path d="M93.9532 57.0208V112.604H2.41504V32.775L48.16 2.39584L74.7278 20.7479L84.3888 27.4083"
                  stroke="#3B444B" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M29.708 64.7354L48.3538 82.6083L111.585 19.3583" stroke="#3B444B" strokeWidth="2.5"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <defs>
                <linearGradient id="paint0_linear_818_385" x1="62" y1="54.5" x2="3.5" y2="111.5"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A9F387" stopOpacity="0"/>
                    <stop offset="1" stopColor="#A9F387"/>
                </linearGradient>
            </defs>
        </svg>
    </div>
}

export function IconHeart() {
    return <div className="w-28">
        <svg xmlns="http://www.w3.org/2000/svg" width="125" height="115" viewBox="0 0 125 115" fill="none">
            <path
                d="M93.9696 11.5926C91.1929 10.7158 88.2701 10.2774 85.2498 10.2774C73.2662 10.2774 62.9388 17.4862 58.4571 27.8611C53.9754 17.535 43.6481 10.2774 31.6645 10.2774C15.5401 10.2774 2.33865 23.3799 2.43608 39.5023C2.63094 72.0881 51.15 97.9521 58.4571 112.565C65.7642 97.9521 113.845 72.1368 114.478 39.5023C114.478 36.0928 113.894 32.7806 112.822 29.712"
                fill="url(#paint0_linear_818_380)" fillOpacity="0.5"/>
            <path d="M40.043 48.5133L58.8466 66.6815L122.564 2.43539" stroke="#3B444B" strokeWidth="2.5"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M93.9696 11.5926C91.1929 10.7158 88.2701 10.2774 85.2498 10.2774C73.2662 10.2774 62.9388 17.4862 58.4571 27.8611C53.9754 17.535 43.6481 10.2774 31.6645 10.2774C15.5401 10.2774 2.33865 23.3799 2.43608 39.5023C2.63094 72.0881 51.15 97.9521 58.4571 112.565C65.7642 97.9521 113.845 72.1368 114.478 39.5023C114.478 36.0928 113.894 32.7806 112.822 29.712"
                stroke="#3B444B" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <defs>
                <linearGradient id="paint0_linear_818_380" x1="72.0005" y1="41" x2="33.0005" y2="97"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A9F387" stopOpacity="0"/>
                    <stop offset="1" stopColor="#A9F387"/>
                </linearGradient>
            </defs>
        </svg>
    </div>
}

export function IconPriceTag() {
    return <div className="w-28">
        <svg xmlns="http://www.w3.org/2000/svg" width="130" height="113" viewBox="0 0 130 113" fill="none">
            <path
                d="M99.7233 49.0646L107.813 57.163C109.658 59.0101 109.658 61.9937 107.813 63.8408L62.4453 109.259C60.6003 111.106 57.62 111.106 55.775 109.259L3.73714 57.163C2.83831 56.2632 2.36523 55.0792 2.36523 53.8005V8.43002C2.36523 5.82524 4.49406 3.69406 7.09595 3.69406H52.4635C53.7408 3.69406 54.9235 4.21502 55.8223 5.06749L81.5101 30.7838"
                fill="url(#paint0_linear_818_374)" fillOpacity="0.5"/>
            <path d="M47.4961 47.1702L65.7093 64.8353L127.634 2.36798" stroke="#3B444B" strokeWidth="2.5"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M99.7233 49.0646L107.813 57.163C109.658 59.0101 109.658 61.9937 107.813 63.8408L62.4453 109.259C60.6003 111.106 57.62 111.106 55.775 109.259L3.73714 57.163C2.83831 56.2632 2.36523 55.0792 2.36523 53.8005V8.43002C2.36523 5.82524 4.49406 3.69406 7.09595 3.69406H52.4635C53.7408 3.69406 54.9235 4.21502 55.8223 5.06749L81.5101 30.7838"
                stroke="#3B444B" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M26.019 36.8458C31.2444 36.8458 35.4805 32.605 35.4805 27.3738C35.4805 22.1426 31.2444 17.9019 26.019 17.9019C20.7936 17.9019 16.5576 22.1426 16.5576 27.3738C16.5576 32.605 20.7936 36.8458 26.019 36.8458Z"
                stroke="#3B444B" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <defs>
                <linearGradient id="paint0_linear_818_374" x1="78.5" y1="44" x2="56" y2="108"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A9F387" stopOpacity="0"/>
                    <stop offset="1" stopColor="#A9F387"/>
                </linearGradient>
            </defs>
        </svg>
    </div>
}

export function IconCalendar() {
    return <div className="w-28">
        <svg xmlns="http://www.w3.org/2000/svg" width="155" height="117" viewBox="0 0 155 117" fill="none">
            <path
                d="M7.86955 114L110.13 114C112.82 114 115 111.829 115 109.15L115 21.85C115 19.1714 112.82 17 110.13 17L7.86956 17C5.18018 17 3 19.1714 3 21.85L2.99999 109.15C2.99999 111.829 5.18017 114 7.86955 114Z"
                fill="url(#paint0_linear_818_390)" fillOpacity="0.5"/>
            <path
                d="M114.905 81.5587V109.688C114.905 112.369 112.705 114.562 110.016 114.562H7.33441C4.64514 114.562 2.44482 112.369 2.44482 109.688V21.9375C2.44482 19.2562 4.64514 17.0625 7.33441 17.0625H110.016C112.705 17.0625 114.905 19.2562 114.905 21.9375V50.6513"
                stroke="#3B444B" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.44482 41.4375H114.905" stroke="#3B444B" strokeWidth="2.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M95.3472 2.4375V26.8125" stroke="#3B444B" strokeWidth="2.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M22.0029 2.4375V26.8125" stroke="#3B444B" strokeWidth="2.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M44.3975 58.6462H49.2871" stroke="#3B444B" strokeWidth="2.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M19.9497 78.1462H24.8393" stroke="#3B444B" strokeWidth="2.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M44.3975 78.1462H49.2871" stroke="#3B444B" strokeWidth="2.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M19.9497 97.6462H24.8393" stroke="#3B444B" strokeWidth="2.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M44.3975 97.6462H49.2871" stroke="#3B444B" strokeWidth="2.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M69.7256 73.8075L88.5505 91.9913L152.555 27.69" stroke="#3B444B" strokeWidth="2.5"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <defs>
                <linearGradient id="paint0_linear_818_390" x1="112.462" y1="19.2186" x2="5.2913" y2="113.138"
                                gradientUnits="userSpaceOnUse">
                    <stop offset="0.403635" stopColor="#A9F387" stopOpacity="0"/>
                    <stop offset="1" stopColor="#A9F387"/>
                </linearGradient>
            </defs>
        </svg>
    </div>
}

export function IconPriceTagChecked() {
    return <svg width="100%" height="100%" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_816244_1189)">
                <path
                    d="M23.0131 13.0262L24.88 15.1763C25.3057 15.6666 25.3057 16.4587 24.88 16.9491L14.4105 29.0069C13.9848 29.4973 13.297 29.4973 12.8712 29.0069L0.862492 15.1763C0.655069 14.9374 0.545898 14.623 0.545898 14.2836V2.23829C0.545898 1.54676 1.03716 0.980957 1.6376 0.980957H12.107C12.4018 0.980957 12.6747 1.11926 12.8821 1.34558L18.8101 8.17291"
                    fill="url(#paint0_linear_816244_1189)" fillOpacity="0.5"/>
                <path d="M10.9609 12.5233L15.164 17.2131L29.4544 0.628906" stroke="#3B444B" strokeLinecap="round"
                      strokeLinejoin="round"/>
                <path
                    d="M23.0131 13.0262L24.88 15.1763C25.3057 15.6666 25.3057 16.4587 24.88 16.9491L14.4105 29.0069C13.9848 29.4973 13.297 29.4973 12.8712 29.0069L0.862492 15.1763C0.655069 14.9374 0.545898 14.623 0.545898 14.2836V2.23829C0.545898 1.54676 1.03716 0.980957 1.6376 0.980957H12.107C12.4018 0.980957 12.6747 1.11926 12.8821 1.34558L18.8101 8.17291"
                    stroke="#3B444B" strokeLinecap="round" strokeLinejoin="round"/>
                <path
                    d="M6.00421 9.78227C7.21007 9.78227 8.18761 8.65641 8.18761 7.2676C8.18761 5.87879 7.21007 4.75293 6.00421 4.75293C4.79834 4.75293 3.8208 5.87879 3.8208 7.2676C3.8208 8.65641 4.79834 9.78227 6.00421 9.78227Z"
                    stroke="#3B444B" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_816244_1189" x1="18.1155" y1="11.6816" x2="11.4795" y2="28.0889"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A9F387" stopOpacity="0"/>
                    <stop offset="1" stopColor="#A9F387"/>
                </linearGradient>
                <clipPath id="clip0_816244_1189">
                    <rect width="30" height="30" fill="white"/>
                </clipPath>
            </defs>
        </svg>
}
export function IconPriceTagUnchecked() {
    return <svg width="100%" height="100%" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M23.0131 13.0262L24.88 15.1763C25.3057 15.6666 25.3057 16.4587 24.88 16.9491L14.4105 29.0069C13.9848 29.4973 13.297 29.4973 12.8712 29.0069L0.862492 15.1763C0.655069 14.9374 0.545898 14.623 0.545898 14.2836V2.23829C0.545898 1.54676 1.03716 0.980957 1.6376 0.980957H12.107C12.4018 0.980957 12.6747 1.11926 12.8821 1.34558L18.8101 8.17291"
            fill="url(#paint0_linear_816245_1238)" fillOpacity="0.5"/>
        <path
            d="M23.0131 13.0262L24.88 15.1763C25.3057 15.6666 25.3057 16.4587 24.88 16.9491L14.4105 29.0069C13.9848 29.4973 13.297 29.4973 12.8712 29.0069L0.862492 15.1763C0.655069 14.9374 0.545898 14.623 0.545898 14.2836V2.23829C0.545898 1.54676 1.03716 0.980957 1.6376 0.980957H12.107C12.4018 0.980957 12.6747 1.11926 12.8821 1.34558L18.8101 8.17291"
            stroke="#3B444B" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M6.00421 9.78227C7.21007 9.78227 8.18761 8.65641 8.18761 7.2676C8.18761 5.87879 7.21007 4.75293 6.00421 4.75293C4.79834 4.75293 3.8208 5.87879 3.8208 7.2676C3.8208 8.65641 4.79834 9.78227 6.00421 9.78227Z"
            stroke="#3B444B" strokeLinecap="round" strokeLinejoin="round"/>
        <defs>
            <linearGradient id="paint0_linear_816245_1238" x1="18.1155" y1="11.6816" x2="11.4795" y2="28.0889"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#A9F387" stopOpacity="0"/>
                <stop offset="1" stopColor="#A9F387"/>
            </linearGradient>
        </defs>
    </svg>
}

export function IconHeartUnchecked() {
    return <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_816308_1603)">
            <path
                d="M15.0351 2.01631C14.5908 1.86384 14.1232 1.7876 13.6399 1.7876C11.7225 1.7876 10.0702 3.0413 9.3531 4.84562C8.63603 3.04977 6.98366 1.7876 5.06627 1.7876C2.48638 1.7876 0.374146 4.06629 0.389734 6.87019C0.420911 12.5373 8.18397 17.0354 9.3531 19.5767C10.5222 17.0354 18.2151 12.5458 18.3165 6.87019C18.3165 6.27722 18.2229 5.70119 18.0515 5.16752"
                fill="url(#paint0_linear_816308_1603)" fillOpacity="0.5"/>
            <path
                d="M15.0351 2.01631C14.5908 1.86384 14.1232 1.7876 13.6399 1.7876C11.7225 1.7876 10.0702 3.0413 9.3531 4.84562C8.63603 3.04977 6.98366 1.7876 5.06627 1.7876C2.48638 1.7876 0.374146 4.06629 0.389734 6.87019C0.420911 12.5373 8.18397 17.0354 9.3531 19.5767C10.5222 17.0354 18.2151 12.5458 18.3165 6.87019C18.3165 6.27722 18.2229 5.70119 18.0515 5.16752"
                stroke="#DADCE0" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <linearGradient id="paint0_linear_816308_1603" x1="11.52" y1="7.13065" x2="4.56016" y2="16.3248"
                            gradientUnits="userSpaceOnUse">
                <stop stopColor="#A9F387" stopOpacity="0"/>
                <stop offset="1" stopColor="#DADCE0"/>
            </linearGradient>
            <clipPath id="clip0_816308_1603">
                <rect width="20" height="20" fill="white"/>
            </clipPath>
        </defs>
    </svg>

}


export function IconLeafUnchecked() {
    return <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.444 7.7022C19.3012 11.7257 17.1155 16.3342 16.0583 18.1319C15.9033 18.3834 15.695 18.5979 15.4483 18.7606C15.2015 18.9232 14.9222 19.0301 14.6297 19.0735C12.5583 19.4302 7.40113 19.8298 4.62971 15.8062C1.90113 12.0111 2.01542 5.33377 2.25828 2.15207C2.26785 1.91503 2.33365 1.68364 2.45028 1.47696C2.56691 1.27028 2.73101 1.09422 2.92908 0.963262C3.12715 0.832308 3.35352 0.750209 3.58958 0.723722C3.82563 0.697235 4.06462 0.727116 4.28685 0.810908C7.35828 1.69551 13.7583 3.907 16.444 7.7022Z"
            fill="url(#paint0_linear_816313_1673)" fillOpacity="0.5" stroke="#DADCE0" strokeLinecap="round"
            strokeLinejoin="round"/>
        <rect x="12.5454" y="5.43115" width="2.66667" height="3.6886" transform="rotate(-45 12.5454 5.43115)"
              fill="white"/>
        <defs>
            <linearGradient id="paint0_linear_816313_1673" x1="15.6667" y1="3.3335" x2="5" y2="15.3335"
                            gradientUnits="userSpaceOnUse">
                <stop offset="0.319359" stopColor="#D9D9D9" stopOpacity="0"/>
                <stop offset="1" stopColor="#DADCE0"/>
            </linearGradient>
        </defs>
    </svg>

}

export function IconLeafChecked() {
    return <svg width="100%" height="100%" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24.666 11.5531C28.9518 17.5883 25.6732 24.5011 24.0875 27.1976C23.855 27.5748 23.5426 27.8966 23.1725 28.1407C22.8022 28.3846 22.3833 28.5449 21.9446 28.61C18.8374 29.1451 11.1017 29.7444 6.94456 23.7091C2.8517 18.0163 3.02313 8.00041 3.38742 3.22786C3.40177 2.8723 3.50047 2.52522 3.67542 2.21519C3.85036 1.90517 4.09651 1.64108 4.39362 1.44465C4.69072 1.24822 5.03028 1.12507 5.38436 1.08534C5.73845 1.04561 6.09693 1.09043 6.43027 1.21612C11.0374 2.54302 20.6374 5.86025 24.666 11.5531Z"
                fill="url(#paint0_linear_816363_762)" fillOpacity="0.5" stroke="#3B444B" strokeLinecap="round"
                strokeLinejoin="round"/>
            <rect x="18.8179" y="8.14648" width="4" height="5.5329" transform="rotate(-45 18.8179 8.14648)"
                  fill="white"/>
            <path d="M9.61035 12.6556L14.1232 17.3951L29.4155 0.635254" stroke="#3B444B" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <defs>
                <linearGradient id="paint0_linear_816363_762" x1="23.5" y1="5" x2="7.5" y2="23"
                                gradientUnits="userSpaceOnUse">
                    <stop offset="0.319359" stopColor="#D9D9D9" stopOpacity="0"/>
                    <stop offset="1" stopColor="#A9F387"/>
                </linearGradient>
            </defs>
        </svg>
}

export function IconHeartChecked() {
    return <svg width="100%" height="100%" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_816244_1163)">
                <path
                    d="M22.5526 3.02423C21.8862 2.79551 21.1848 2.68115 20.4599 2.68115C17.5838 2.68115 15.1053 4.56171 14.0297 7.26819C12.954 4.57442 10.4755 2.68115 7.59941 2.68115C3.72957 2.68115 0.561219 6.0992 0.584601 10.305C0.631367 18.8057 12.276 25.5528 14.0297 29.3648C15.7834 25.5528 27.3227 18.8184 27.4747 10.305C27.4747 9.41559 27.3344 8.55155 27.0772 7.75104"
                    fill="url(#paint0_linear_816244_1163)" fillOpacity="0.5"/>
                <path d="M9.61035 12.6556L14.1232 17.3951L29.4155 0.635254" stroke="#3B444B" strokeLinecap="round"
                      strokeLinejoin="round"/>
                <path
                    d="M22.5526 3.02423C21.8862 2.79551 21.1848 2.68115 20.4599 2.68115C17.5838 2.68115 15.1053 4.56171 14.0297 7.26819C12.954 4.57442 10.4755 2.68115 7.59941 2.68115C3.72957 2.68115 0.561219 6.0992 0.584601 10.305C0.631367 18.8057 12.276 25.5528 14.0297 29.3648C15.7834 25.5528 27.3227 18.8184 27.4747 10.305C27.4747 9.41559 27.3344 8.55155 27.0772 7.75104"
                    stroke="#3B444B" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_816244_1163" x1="17.2801" y1="10.6957" x2="6.84023" y2="24.487"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A9F387" stopOpacity="0"/>
                    <stop offset="1" stopColor="#A9F387"/>
                </linearGradient>
                <clipPath id="clip0_816244_1163">
                    <rect width="30" height="30" fill="white"/>
                </clipPath>
            </defs>
        </svg>
}


export function NumberCircle({number, color}: { number: string, color: string }) {
    return <span
        className="p-4 pt-6 rounded-full h-12 w-12"
        style={{backgroundColor: color, color: COLOR_DARK_GREY, fontSize: "2rem", lineHeight: "0"}}>{number}</span>
}

export function Circle() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="57" height="57" viewBox="0 0 57 57" fill="none">
        <circle cx="28.5" cy="28.5" r="28.5" fill="#14C693"/>
    </svg>
}

export function ChevronRight() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="13" height="23" viewBox="0 0 13 23" fill="none">
        <g clipPath="url(#clip0_632_1686)">
            <path
                d="M0.214284 21.6362L1.35714 22.7788L1.85714 23L2.35714 22.7788L12.7857 12.016L13 11.5L12.7857 10.984L2.35714 0.221154L1.85714 9.33209e-07L1.35714 0.221154L0.214286 1.40064L-6.41852e-08 1.91667L0.214286 2.43269L9 11.5L0.214284 20.5673L-1.73979e-06 21.0833L0.214284 21.6362Z"
                fill="#037CB0"/>
        </g>
        <defs>
            <clipPath id="clip0_632_1686">
                <rect width="13" height="23" fill="white" transform="translate(13 23) rotate(-180)"/>
            </clipPath>
        </defs>
    </svg>
}

export function ChevronLeft() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="13" height="23" viewBox="0 0 13 23" fill="none">
        <g clipPath="url(#clip0_632_1693)">
            <path
                d="M12.7857 1.36378L11.6429 0.221154L11.1429 0L10.6429 0.221154L0.214286 10.984L0 11.5L0.214286 12.016L10.6429 22.7788L11.1429 23L11.6429 22.7788L12.7857 21.5994L13 21.0833L12.7857 20.5673L4 11.5L12.7857 2.43269L13 1.91667L12.7857 1.36378Z"
                fill="#037CB0"/>
        </g>
        <defs>
            <clipPath id="clip0_632_1693">
                <rect width="13" height="23" fill="white"/>
            </clipPath>
        </defs>
    </svg>
}

export function EmptyDot() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
        <circle cx="6" cy="6" r="5.5" stroke="#037CB0"/>
    </svg>
}

export const EmptyDotURI: string = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIiBmaWxsPSJub25lIj4KICAgICAgICA8Y2lyY2xlIGN4PSI2IiBjeT0iNiIgcj0iNS41IiBzdHJva2U9IiMwMzdDQjAiLz4KICAgIDwvc3ZnPg=="
export const FilledDotURI: string = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIiBmaWxsPSJub25lIj4KICAgICAgICA8Y2lyY2xlIGN4PSI2IiBjeT0iNiIgcj0iNS41IiBmaWxsPSIjMDM3Q0IwIiBzdHJva2U9IiMwMzdDQjAiLz4KICAgIDwvc3ZnPg=="

export function FilledDot() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
        <circle cx="6" cy="6" r="5.5" fill="#037CB0" stroke="#037CB0"/>
    </svg>
}

export function IconAmbulance({strokeWidth}:{strokeWidth:number}) {
    return <svg width="100%" height="100%" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.15852 10.7734V4C9.15852 3.44772 8.7108 3 8.15852 3H1.70215C1.14986 3 0.702148 3.44772 0.702148 4V10.6256C0.702148 11.1779 1.14986 11.6256 1.70215 11.6256H2.48926"
            stroke="black" strokeLinecap="round" strokeWidth={strokeWidth} strokeLinejoin="round"/>
        <path d="M13.2979 7.73242H9.1604" stroke="black" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M11.6377 11.6257H12.3196C12.8747 11.6257 13.3236 11.1736 13.3195 10.6184L13.2964 7.43811L11.9039 4.58726C11.7362 4.24389 11.3875 4.02612 11.0054 4.02612H9.14551"
            stroke="black" strokeLinecap="round"strokeWidth={strokeWidth}  strokeLinejoin="round"/>
        <path
            d="M3.89801 13.052C4.669 13.052 5.29401 12.427 5.29401 11.656C5.29401 10.885 4.669 10.26 3.89801 10.26C3.12702 10.26 2.50201 10.885 2.50201 11.656C2.50201 12.427 3.12702 13.052 3.89801 13.052Z"
            stroke="black" strokeLinecap="round" strokeWidth={strokeWidth} strokeLinejoin="round"/>
        <path
            d="M10.235 13.052C11.006 13.052 11.631 12.427 11.631 11.656C11.631 10.885 11.006 10.26 10.235 10.26C9.464 10.26 8.83899 10.885 8.83899 11.656C8.83899 12.427 9.464 13.052 10.235 13.052Z"
            stroke="black" strokeLinecap="round" strokeWidth={strokeWidth} strokeLinejoin="round"/>
        <path d="M3.43134 7H6.42932" stroke="black" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.93042 5.50098V8.49896" stroke="black" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.83887 11.6255H5.29395" stroke="black"strokeWidth={strokeWidth}  strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
}
export function IconTree({strokeWidth}:{strokeWidth:number}) {
    return <svg width="100%" height="100%" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1222_31828)">
            <path
                d="M9.24071 1.35904C9.87547 1.90434 10.2938 2.65902 10.42 3.48628H10.5C11.2956 3.48628 12.0587 3.80235 12.6213 4.36496C13.1839 4.92757 13.5 5.69063 13.5 6.48628C13.5 7.28193 13.1839 8.04499 12.6213 8.6076C12.0587 9.17021 11.2956 9.48628 10.5 9.48628H3.5C2.70435 9.48628 1.94129 9.17021 1.37868 8.6076C0.816071 8.04499 0.5 7.28193 0.5 6.48628C0.5 5.69063 0.816071 4.92757 1.37868 4.36496C1.94129 3.80235 2.70435 3.48628 3.5 3.48628C3.62615 2.65902 4.04453 1.90434 4.67929 1.35904C5.31405 0.813741 6.12318 0.513916 6.96 0.513916C7.79682 0.513916 8.60595 0.813741 9.24071 1.35904Z"
                stroke="black" strokeLinecap="round" strokeWidth={strokeWidth} strokeLinejoin="round"/>
            <path d="M5.42004 6.48608L6.92004 7.98608V13.4861" strokeWidth={strokeWidth} stroke="black" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M6.92004 7.98608L8.42004 6.48608" strokeWidth={strokeWidth} stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_1222_31828">
                <rect width="14" height="14" fill="white"/>
            </clipPath>
        </defs>
    </svg>
}
export function IconCapitol({strokeWidth}:{strokeWidth:number}) {
    return <svg width="100%" height="100%" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1222_33683)">
            <path d="M0.707031 13.4375H13.457" strokeWidth={strokeWidth} stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.85999 5.68749L7.08209 3.15625L10.3042 5.68749" strokeWidth={strokeWidth}  stroke="black" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M4.34998 7.8125H9.81426V13.4375H4.34998V7.8125Z" strokeWidth={strokeWidth} stroke="black" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M3.23828 5.6875H10.9258V7.80921H3.23828V5.6875Z" strokeWidth={strokeWidth} stroke="black" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M1.61768 7.8092V13.4375" stroke="black" strokeWidth={strokeWidth}  strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.5463 7.8092V13.4354" stroke="black" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M0.707031 7.8092H13.457" stroke="black" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.08215 3.15622L7.08203 0.674072" stroke="black" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.08215 0.674072H8.33255" stroke="black" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M6.09546 11.203C6.09546 10.689 6.5122 10.2722 7.02628 10.2722C7.54035 10.2722 7.95709 10.689 7.95709 11.203V13.4375H6.09546V11.203Z"
                stroke="black" strokeLinecap="round" strokeWidth={strokeWidth} strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_1222_33683">
                <rect width="14" height="14" fill="white"/>
            </clipPath>
        </defs>
    </svg>

}