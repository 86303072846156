import {useForm} from "@mantine/form";
import {GradientBox, HDivider, HStack, Text2Xl, TextBase, VStack} from "./DesignBase.js";
import {signIn} from "~/library-pages-auth";
import {TextInput} from "@mantine/core";
import { Logo} from "./Icons.js";
import {DesignedButton} from "./DesignComponents.js";
import classNames from "classnames";


export function SignIn({redirect, logo}: { redirect: string, logo?: boolean }) {

    const decodedRedirectUrl = decodeURIComponent(redirect)

    const emailForm = useForm({
        initialValues: {email: ''},
        validate: {
            email: (value) => (/^\S+@\S+\.\S{2,}$/.test(value) ? null : 'Invalid email'),
        },
    })

    return (
        <GradientBox>
            <VStack center>
                {logo && <Logo/>}
                <Text2Xl m0 classNames="mb-12">
                    Login to SeeAir
                </Text2Xl>
                <form className="w-60"
                    onSubmit={emailForm.onSubmit(async (values) => {
                        console.log(`redirect: ${redirect} ; ${decodedRedirectUrl}`)
                        await signIn('resend', {callbackUrl: decodedRedirectUrl, email: values.email})
                    })}
                >
                    <TextInput
                        type="email"
                        className="mb-6 w-full"
                        classNames={{input: classNames("border border-[#037CB0] flex-shrink-0 h-12 bg-white designed-input text-base leading-7 pl-5 w-full rounded-2xl")}}
                        placeholder="Email Address"
                        {...emailForm.getInputProps('email')}
                    />
                    <DesignedButton m0 submit wFull className="h-12"><TextBase m0 wFull center>Sign in with Email</TextBase></DesignedButton>
                </form>
                <div className="w-60">
                    <HDivider />
                    <DesignedButton
                        inverse
                        outline
                        wFull
                        m0 className="h-12"
                        onClick={() => signIn('google', {callbackUrl: decodedRedirectUrl})}
                    >
                        <HStack center relative>
                            <img style={{width: "39px", height: "39px"}} src="/design-assets/google.svg"/>
                            <TextBase m0 wFull center>Sign in with Google</TextBase>
                        </HStack>
                    </DesignedButton>
                </div>
                <div className="w-60">
                <HDivider />
                    <DesignedButton
                        inverse
                        outline
                        wFull
                        m0 className="h-12"
                        onClick={() => signIn('apple', {callbackUrl: decodedRedirectUrl})}
                    >
                        <HStack center relative>
                            <img style={{width: "39px", height: "39px"}} src="/design-assets/apple.svg"/>
                            <TextBase m0 wFull center>Sign in with Apple</TextBase>
                        </HStack>
                    </DesignedButton>
                </div>

            </VStack>
        </GradientBox>
    )
}