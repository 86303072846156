import {createFileRoute} from "@tanstack/react-router";
import {AdminOnlyLoad, trpc} from "~/lib-client";
import {AdminUserPage} from "@seeair/admin-components";



export const Route = createFileRoute('/admin/_admin/user/$user_id/')({
    component: function AdminUserPageComponent() {
        const {user_id} = Route.useParams()
        const [user] = trpc.ADMIN.getUser.useSuspenseQuery({user_id})
        const [homes] = trpc.ADMIN.getHomesAggregateForUser.useSuspenseQuery({user_id})

        return <AdminUserPage
            user={user}
            homes={homes}/>
    },
    wrapInSuspense: true,
    beforeLoad: AdminOnlyLoad,
    loader: ({params:{user_id},context}) => {
        context.utils.ADMIN.getHomesAggregateForUser.prefetch({user_id})
    },
})

