import {SeeAirUser} from "@seeair/schemas";
import {useUserSearch} from "./admin-utils.js";
import {Box, HStack, VStack} from "@seeair/shared-components";
import {TextInput} from "@mantine/core";
import {UserCollapse} from "./UserCollapse.js";

export function AdminUserList({allUsers}: { allUsers: Array<SeeAirUser> }) {
    const {usersData, searchText, setSearchText} = useUserSearch(allUsers)

    return <VStack>
        <HStack>
            <TextInput
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
            />
        </HStack>
        <Box>
            {usersData.map((userData) => (
                <UserCollapse
                    key={userData.id}
                    userData={userData}
                />
            ))}
        </Box>
    </VStack>
}