import {Recommendation} from "@seeair/schemas";
import {Table} from "@mantine/core";

export function LineItemTable({rec}: { rec: Recommendation }) {
    const rows: string[][] = (rec.hubspot_line_items || []).reduce((acc, v) => [...acc, [
        v.properties.hs_sku || '',
        v.properties.description || '',
        v.properties.tax || '',
        v.properties.discount || '',
        v.properties.price || '',
        v.properties.quantity || '']], [] as string[][])
    return <Table
        striped
        withTableBorder
        captionSide="bottom"
        data={{
        caption: ` ${rows.length} Line Items from Hubspot`,
        head: ['SKU','Description','Tax','Discount','Price','Quantity'],
        body: rows

    }}/>
}