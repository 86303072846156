import {
    Assessment,
    AssessmentFile,
    AssessmentFiles,
    AssessmentFilesEnum,
    AssessmentFilesEnumSchema,
    FileUploadNonce,
} from "@seeair/schemas";
import dayjs from "dayjs";
import React, {useEffect, useState} from "react";
import {Button, Combobox, FileInput, InputBase, Loader, Table, UnstyledButton, useCombobox} from "@mantine/core";
import {trpc} from "~/lib-client";
import {IconDownload} from "@tabler/icons-react";
import {HStack, TextSm, VStack} from "./DesignBase.js";
import {DesignedButton} from "./DesignComponents.js";
import classNames from "classnames";
import {lastNChars} from './Util.js';

export function AssessmentFilesTable({assessment}: { assessment: Assessment }) {
    const [triggerDownloads, setTriggerDownload] = useState(false)
    const files = assessment.assessment_files || {} as AssessmentFiles
    const allPossibleFiles = Object.keys(AssessmentFilesEnumSchema.Values) as Array<AssessmentFilesEnum>
    return <VStack>
        <DesignedButton onClick={() => {
            setTriggerDownload(true)
        }}>Download All Files</DesignedButton>
        <Table striped highlightOnHover withTableBorder>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>File</Table.Th>
                    <Table.Th>Count</Table.Th>
                    <Table.Th>Version</Table.Th>
                    <Table.Th>Download</Table.Th>
                    <Table.Th>Upload</Table.Th>
                </Table.Tr>
            </Table.Thead>
            {allPossibleFiles.map((k) => <AssessmentFilesRow triggerDownload={triggerDownloads} key={k} name={k}
                                                             fileVersions={files[k]}
                                                             assessment={assessment}/>)}
        </Table>
    </VStack>
}

export function AssessmentFilesRow({triggerDownload, assessment, fileVersions, name, onUploadComplete}: {
    assessment: Assessment,
    fileVersions: Array<AssessmentFile> | undefined,
    name: keyof AssessmentFiles,
    triggerDownload: boolean,
    onUploadComplete?: () => void
}) {
    const utils = trpc.useUtils()
    const sortedFileVersions = [...(fileVersions ?? [])]
        .sort((o1, o2) => dayjs(o1.created_date).unix() - dayjs(o2.created_date).unix())
    const [selectedFileVersion, setSelectedFileVersion] = useState(sortedFileVersions[0])
    const [fileUpload, setFileUpload] = useState<File | null>(null)
    const {
        data: saveUrlResponse,
        isError: isSaveUrlError,
        error: saveUrlError,
        isPending: isSaveUrlPending,
        mutate: saveUrl
    } =
        trpc.ADMIN.saveAssessmentFileUploadUrl.useMutation({
            onSuccess: () => {
                utils.ADMIN.getHomesAggregateForUser.invalidate()
                setFileUpload(null)
                if (onUploadComplete) { // Added for dropdown modal
                    onUploadComplete()
                }
            }
        })
    const {
        data: fileUploadUrl
        , isError: isUploadFileError,
        error: fileUploadError,
        isPending: isUploadFilePending,
        mutate: uploadFile
    } = trpc.ADMIN.getAssessmentFileUploadUrl.useMutation({
        onSuccess: async ({url, nonce}: { url: string, nonce: FileUploadNonce }) => {
            try {
                await fetch(url, {
                    method: "PUT",
                    body: fileUpload!,
                    headers: {
                        "Content-Type": fileUpload!.type
                    }
                })
                saveUrl(nonce)
            } catch (e) {
                console.log("upload error", e)
            }
        }
    })
    const {
        data: fileDownloadUrl,
        isError: isFileDownloadUrlError,
        isPending: isFileDownloadUrlPending,
        mutate: getDownloadUrl
    } = trpc.ADMIN.getAssessmentFileDownloadUrl.useMutation({
        onSuccess: async ({url}: { url: string }) => {
            if (triggerDownload) {
                window.open(url, "_blank")
            }
        }
    })

    useEffect(() => {
        if (triggerDownload && fileVersions) {
            const firstFileVersion = [...fileVersions].sort((o1, o2) => dayjs(o1.created_date).unix() - dayjs(o2.created_date).unix())[0]!
            getDownloadUrl({s3Url: firstFileVersion.s3_url})
        }
    }, [triggerDownload, fileVersions, getDownloadUrl]);
    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
        onDropdownOpen: (eventSource) => {
            if (eventSource === 'keyboard') {
                combobox.selectActiveOption();
            } else {
                combobox.updateSelectedOptionIndex('active');
            }
        },
    });
    const fileUploadComponent = <HStack leftCenter>
        <FileInput
            value={fileUpload}
            onChange={setFileUpload}
            placeholder={`Select File ...`}
            clearable
        />
        <Button disabled={!fileUpload || isUploadFilePending}
                onClick={() => uploadFile({
                    assessment_id: assessment.assessment_id,
                    contentType: fileUpload!.type,
                    assessmentFileEnum: name,
                    fileName: fileUpload!.name
                })}
        >{isUploadFilePending || isSaveUrlPending ? <Loader/> : "Upload"}</Button>
        {isUploadFileError && <span>{fileUploadError.message}</span>}
        {isSaveUrlError && <span>{saveUrlError.message}</span>}
    </HStack>

    const downloadComponent = <Button disabled={!selectedFileVersion} onClick={() => {
        if (fileDownloadUrl) {
            window.open(fileDownloadUrl.url, "_blank")
        } else if (selectedFileVersion) {
            getDownloadUrl({s3Url: selectedFileVersion.s3_url})
        }
    }}>
        {fileDownloadUrl ? <IconDownload/> : isFileDownloadUrlPending ?
            <Loader/> : "Click To Get Download Link"}
    </Button>
    return <Table.Tr>
        <Table.Td>{name}</Table.Td>
        <Table.Td>{fileVersions?.length ?? 0}</Table.Td>
        <Table.Td>
            <Combobox
                store={combobox}
                size="xl"
                disabled={!fileVersions}
            >
                <Combobox.Target targetType="button">
                    <InputBase
                        component="button"
                        type="button"
                        pointer
                        rightSection={<Combobox.Chevron/>}
                        rightSectionPointerEvents="none"
                        onClick={() => combobox.toggleDropdown()}
                    >
                        {selectedFileVersion
                            ? selectedFileVersion.name
                            : null
                        }
                    </InputBase>
                </Combobox.Target>

                <Combobox.Dropdown>
                    <Combobox.Options>
                        {sortedFileVersions.map((f,i) => <UnstyledButton className={classNames("first:border-0 first:mt-0 mt-2 border-t border-black border-solid w-full",{"bg-gray-200":f.s3_url==selectedFileVersion?.s3_url})} onClick={()=>{
                            setSelectedFileVersion(f)
                            combobox.toggleDropdown()
                        }}><FileComboboxItem file={f} /></UnstyledButton>)}
                    </Combobox.Options>
                </Combobox.Dropdown>
            </Combobox>
        </Table.Td>
        <Table.Td>{downloadComponent}</Table.Td>
        <Table.Td>{fileUploadComponent}</Table.Td>
    </Table.Tr>
}
function FileComboboxItem({file}:{file:AssessmentFile}) {
    return <VStack center>
        <TextSm>{lastNChars(file.name,20)}</TextSm>
        <TextSm>{file.created_by}</TextSm>
        <TextSm>{file.created_date}</TextSm>
    </VStack>
}