import {
    EnergyChart, formatMoney,
    formatNumber,
    HDivider,
    HStack,
    IconHeartChecked,
    IconHeartUnchecked,
    IconLeafChecked,
    IconLeafUnchecked,
    IconPriceTagChecked,
    IconPriceTagUnchecked,
    ProjectIcon,
    SheetsDataContext,
    Text2Xl,
    Text4Xl,
    Text5Xl,
    TextBase,
    TextLg,
    TextLink,
    TextSmItalic,
    TextXl,
    VStack
} from "@seeair/shared-components";
import React, {PropsWithChildren, ReactElement, useContext, useState} from "react";
import {Assessment, ASSESSMENT_LABEL_BASELINE, HomeAggregate, RecData, Recommendation} from "@seeair/schemas";
import {Accordion, AccordionControl, AccordionPanel} from "@mantine/core";
import classNames from "classnames";
import {IconChevronDown} from "@tabler/icons-react";

function getLatestFinishedAssessment(home: HomeAggregate): Assessment | undefined {
    const finishedAssessments = home.assessments.filter(a => a.assessment_status == 'done' || a.assessment_status == 'pending_homeowner_review')
    if (finishedAssessments.length == 1) {
        return finishedAssessments[0]
    } else if (finishedAssessments.length > 1) {
        const followUpAssessments = finishedAssessments.filter(a => a.assessment_label != ASSESSMENT_LABEL_BASELINE)
        if (followUpAssessments.length > 0) {
            return followUpAssessments[0]
        } else {
            return finishedAssessments[0]
        }
    }
    return undefined
}

export function MobileRecommendationsPage({home}: { home: HomeAggregate }) {
    const electrification = getLatestFinishedAssessment(home)?.assessment_data?.electrification
    return <VStack classNames="flex-grow bg-white">
        {
            electrification
                ? <VStack>
                    <Text4Xl center>Heating & Cooling Energy Costs</Text4Xl>
                    <HStack baseline center>
                        <TextLg classNames="pr-4">Annual Energy Usage:</TextLg>
                        <TextBase light>
                            {formatNumber(electrification.energy_use.Electric)} kWh
                        </TextBase>
                        <TextLg classNames="pr-4 pl-8">
                            Annual Gas Usage:&nbsp;
                        </TextLg>
                        <TextBase light>
                            {formatNumber(electrification.energy_use.Gas)}M BTU
                        </TextBase>
                    </HStack>
                    <EnergyChart
                        mobile
                        width={850}
                        height={350}
                        energy_chart={electrification.energy_chart}
                    />
                </VStack>
                : <TextBase>Electrification Data Missing</TextBase>
        }
        <HDivider/>
        <Text5Xl normal center>Home Improvement Plan</Text5Xl>
        <VStack center>
            <VStack classNames="max-w-2xl">
                {
                    home.recommendations.length == 0
                        ? <TextBase>Your home is healthy and energy efficient, thanks for doing your part to save the
                            world!</TextBase>
                        : <MobileRecommendationsAccordion recs={home.recommendations}/>
                }
            </VStack>
        </VStack>
    </VStack>
}

export function MobileRecommendationsAccordion({recs}: { recs: Array<Recommendation> }) {
    return <Accordion variant="separated" radius="xl" defaultValue={recs[0]?.recommendation_id}>
        {recs.map((r) => <Rec rec={r} key={r.recommendation_id}/>)}
    </Accordion>
}

function Rec({rec}: { rec: Recommendation }) {
    const {recommendationsSheet} = useContext(SheetsDataContext)
    const matchingRec = recommendationsSheet.find((_rec) => _rec.id === rec.original_rec_id)
    const [expanded, setExpanded] = useState(false)
    return <Accordion.Item value={rec.recommendation_id} className={classNames("border-primary-light-blue mx-4")}>
        <AccordionControl
            icon={<ProjectIcon lg imgSrc={`/recommendation-icons/${rec.original_rec_id}.png`}/>}>
            <TextXl medium center block wFull>{rec.title}</TextXl>
        </AccordionControl>
        <AccordionPanel>
            <VStack classNames="border-t-primary-light-blue">
                <ImpactSummary checkedIcon={<IconHeartChecked/>} uncheckedIcon={<IconHeartUnchecked/>}
                               score={matchingRec?.healthCategory || 0}
                               summary={matchingRec?.healthSummary || ""} title={"Health Impact"}
                               description={matchingRec?.healthDescription || ""} expanded={expanded}/>
                <ImpactSummary checkedIcon={<IconLeafChecked/>} uncheckedIcon={<IconLeafUnchecked/>}
                               score={matchingRec?.climateCategory || 0}
                               summary={matchingRec?.climateSummary || ""} title={"Climate Impact"}
                               description={matchingRec?.climateDescription || ""} expanded={expanded}/>
                <ImpactSummary checkedIcon={<IconPriceTagChecked/>} uncheckedIcon={<IconPriceTagUnchecked/>}
                               score={matchingRec?.priceCategory || 0}
                               summary={matchingRec?.priceSummary || ""} title={"Price Impact"}
                               description={matchingRec?.priceDescription || ""} expanded={expanded}
                               rec_data={rec.rec_data}/>

                <a onClick={() => setExpanded(!expanded)} className="flex flex-row justify-center items-center">
                    <TextLink classNames="transition">{
                        expanded
                            ? "Hide"
                            : "More Info"
                    }
                    </TextLink><IconChevronDown
                    className={classNames("text-blue-400 transition transform", {"actually-rotate-180": expanded})}/></a>
            </VStack>
        </AccordionPanel>
    </Accordion.Item>
}

/*

    Upfront_Cost_Low: z.number(),
    Upfront_Cost_High: z.number(),
    Net_Cost_Low: z.number(),
    Net_Cost_High: z.number(),
    Annual_Savings_Low: z.number(),
    Annual_Savings_High: z.number(),
 */
function avg_fmt_years(high: number, low: number) {
    const avg = ((high + low) / 2)
    if(avg < 0 || Number.isNaN(avg) || !Number.isFinite(avg)) {
        return undefined
    }
    return `${avg.toLocaleString(undefined, {maximumFractionDigits: 1})} years`
}

function avg_fmt_money(high: number, low: number): string {
    return formatMoney((high + low) / 2)
}
function ProformaText({children}:PropsWithChildren) {
    return <TextBase light m0 classNames="leading-8">{children}</TextBase>
}
function Proforma({rec_data}: { rec_data: RecData }) {

    const cost = avg_fmt_money(rec_data.Upfront_Cost_High, rec_data.Upfront_Cost_Low)
    const incentive = avg_fmt_money(
        rec_data.Upfront_Cost_High - rec_data.Net_Cost_High,
        rec_data.Upfront_Cost_Low - rec_data.Net_Cost_Low)
    const net = avg_fmt_money(rec_data.Net_Cost_High, rec_data.Net_Cost_Low)
    const savings = avg_fmt_money(rec_data.Annual_Savings_High, rec_data.Annual_Savings_Low)

    const payback = avg_fmt_years(rec_data.Net_Cost_High / rec_data.Annual_Savings_High, rec_data.Net_Cost_Low / rec_data.Annual_Savings_Low)
    return <VStack classNames="px-8 sm:px-16 border-t-primary-light-blue">
        <Text2Xl medium center>Estimated Cost</Text2Xl>
        <HStack between>
            <ProformaText>Cost</ProformaText>
            <ProformaText>{cost}</ProformaText>
        </HStack>
        <HStack between>
            <ProformaText>Incentive</ProformaText>
            <ProformaText>{incentive}</ProformaText>
        </HStack>
        <HDivider/>
        <HStack between>
            <ProformaText>Net Cost after Incentive</ProformaText>
            <ProformaText>{net}</ProformaText>
        </HStack>
        <HStack between>
            <ProformaText>Annual Energy Savings</ProformaText>
            <ProformaText>{savings}</ProformaText>
        </HStack>
        <HDivider/>
        {payback&&<HStack between>
            <ProformaText>Payback Period</ProformaText>
            <ProformaText>{payback}</ProformaText>
        </HStack>}

        <TextSmItalic classNames="mt-8">*All costs are estimates and subject to change prior to contract sign
            off</TextSmItalic>
    </VStack>
}

function ImpactSummary({checkedIcon, uncheckedIcon, score, summary, title, description, expanded, rec_data}: {
    checkedIcon: ReactElement,
    uncheckedIcon: ReactElement,
    score: number,
    summary: string,
    title: string,
    description: string,
    expanded: boolean,
    rec_data?: RecData
}) {
    const n = Math.floor(score)
    const sizedCheckedIcon = <div className="w-10 h-10">{checkedIcon}</div>
    const sizedUncheckedIcon = <div className="w-10 h-10 opacity-25">{uncheckedIcon}</div>
    console.log(`score: ${n}`)
    if (n == 0) {
        return null
    } else {
        return <div className={classNames("flex items-center", {"flex-row": !expanded, "flex-col": expanded})}>
            {
                expanded
                    ? <React.Fragment>
                        {
                            // we will show the score and description if it is there, but if we have a proform and no desccriptoin, we'll skip score and description
                            description.length>0 || !rec_data && <React.Fragment>
                                    <div className={classNames("px-8 flex flex-row items-center justify-start w-full")}>
                                        {[
                                            ...list(n).map(i => sizedCheckedIcon),
                                            ...(n < 3 ? list(3 - n).map(i => sizedUncheckedIcon) : []),
                                            <TextLg classNames="ml-8">{title}</TextLg>
                                        ]}
                                    </div>
                                    <TextBase classNames={classNames("transition mx-8")}>
                                        {description}
                                    </TextBase>
                                </React.Fragment>
                        }
                        {rec_data && expanded && <Proforma rec_data={rec_data}/>}
                    </React.Fragment>
                    : <React.Fragment>
                        <div className={classNames("px-8 flex flex-row items-center justify-start")}>
                            {sizedCheckedIcon}
                        </div>
                        <TextLg classNames={classNames("transition mx-8")}>
                            {summary}
                        </TextLg>
                    </React.Fragment>
            }
        </div>
    }
}

function list(n: number): Array<number> {
    return Array.from(new Array(n).keys())
}