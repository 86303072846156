import {DesignedButton, HStack} from "@seeair/shared-components";
import {Checkbox, Loader, NativeSelect} from "@mantine/core";
import {Assessment, AssessmentStatus, AssessmentStatusSchema} from "@seeair/schemas";
import {useState} from "react";
import {trpc} from "~/lib-client";
import {notifications} from "@mantine/notifications";

export function AssessmentStatusWidget({assessment}:{assessment:Assessment}){
    const utils = trpc.useUtils()
    const [status,setStatus] = useState(assessment.assessment_status)
    const [overrideAssessmentStatusChange,setOverrideAssessmentStatusChange] = useState(false)
    const {isPending:setAssessmentIsPending,isError:setAssessmentIsError,mutate:saveStatusChange} = trpc.ADMIN.setAssessmentStatus.useMutation({
        onSuccess:(data,variables,context) =>{
            if(data.success) {
                notifications.show({message: 'Success!'})
            } else {
                notifications.show({message:`Failure: ${JSON.stringify(data.errors)}`,})
            }
            utils.ADMIN.getHomesAggregateForUser.invalidate()
        },
        onError:(error) => {
            notifications.show({message: `Failure: ${error.message}`})
        }
    })
    return <HStack leftCenter>
        <NativeSelect
            size="xl"
            data={AssessmentStatusSchema.options}
            value={status || "not_started"}
            onChange={(event) => setStatus(event.target.value as AssessmentStatus)}
        />
        <DesignedButton
            disabled={status == assessment.assessment_status || setAssessmentIsPending}
            onClick={() => saveStatusChange({
                assessment_id: assessment.assessment_id,
                home_id: assessment.home_id,
                status: status!,
                override: overrideAssessmentStatusChange
            })}
        >
            {setAssessmentIsPending ? <Loader/> : "Save"}
        </DesignedButton>
        Override
        <Checkbox checked={overrideAssessmentStatusChange} onChange={(event)=>setOverrideAssessmentStatusChange(event.currentTarget.checked)}>
        </Checkbox>
    </HStack>
}