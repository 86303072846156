import {createFileRoute} from '@tanstack/react-router'
import {
    AdminOnlyLoad,
    trpc,
} from '~/lib-client'
import {AdminUserList, AdminRecentPage} from '@seeair/admin-components';
import {Tabs} from "@mantine/core";
import {IconClock, IconSelectAll} from "@tabler/icons-react";
import {COLOR_WHITE, FullWithDesignedSection} from "@seeair/shared-components";

export const Route = createFileRoute('/admin/_admin/')({
    component: function AdminComponent(){
        const [recentUsers] = trpc.ADMIN.listRecentUsersAggregate.useSuspenseQuery()
        const [allUsers] = trpc.ADMIN.listUsers.useSuspenseQuery()
        return <FullWithDesignedSection background={COLOR_WHITE}>
            <Tabs defaultValue="recent">
                <Tabs.List>
                    <Tabs.Tab value="recent" leftSection={<IconClock/>}>Recent</Tabs.Tab>
                    <Tabs.Tab value="all" leftSection={<IconSelectAll/>}>All</Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="recent">
                    <AdminRecentPage users={recentUsers} />
                </Tabs.Panel>
                <Tabs.Panel value="all">
                    <AdminUserList allUsers={allUsers} />
                </Tabs.Panel>
            </Tabs>
        </FullWithDesignedSection>
    },
    wrapInSuspense: true,
    beforeLoad: AdminOnlyLoad
})
