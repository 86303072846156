import {createFileRoute} from '@tanstack/react-router'
import {trpc} from "~/lib-client";
import React from "react";
import {BlogIndexPage} from "@seeair/homeowner-components";

export const Route = createFileRoute('/blog/_blog/')({
    component: function BlogIndexComponent() {
        const [blogs] = trpc.PUBLIC.getAllBlogs.useSuspenseQuery()
        return <BlogIndexPage blogs={blogs} />
    }
})