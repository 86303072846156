import {Assessment, HomeAggregate} from "@seeair/schemas";
import {buildShortAddress} from "~/lib-client";
import React from "react";
import {AssessmentNotesPanel} from './AssessmentNotesPanel.js';
import {AdminRecommendationsPanel} from './AdminRecommendationsPanel.js';
import {
    AssessmentFilesTable,
    COLOR_WHITE,
    EditUtilitiesPanel,
    FullWithDesignedSection, HStack, RStack, Text2Xl, Text4Xl
} from "@seeair/shared-components";
import {Tabs} from "@mantine/core";
import {IconBulb, IconChecklist, IconFiles, IconJson, IconNote} from "@tabler/icons-react";
import {AssessmentStatusWidget} from './AssessmentStatusWidget.js';
import {EditMissingHomeDetailsPanel} from "@seeair/shared-components";

export function AdminAssessmentPage({home, assessment}: {
    home: HomeAggregate
    assessment: Assessment
}) {
    return (
        <FullWithDesignedSection background={COLOR_WHITE}>
            <RStack xl>
                <HStack baseline>
                    <Text4Xl>{buildShortAddress(home.address)}</Text4Xl>
                    <Text2Xl classNames="ml-4">{assessment.assessment_label}</Text2Xl>
                </HStack>
                <AssessmentStatusWidget assessment={assessment} />
            </RStack>
            <Tabs defaultValue="home_details">
                <Tabs.List>
                    <Tabs.Tab value="home_details" leftSection={<IconJson/>}><Text2Xl>Home Details</Text2Xl></Tabs.Tab>
                    <Tabs.Tab value="assessment_files" leftSection={<IconFiles/>}><Text2Xl>Assessment Files</Text2Xl></Tabs.Tab>
                    <Tabs.Tab value="utilities" leftSection={<IconBulb/>}><Text2Xl>Utilities</Text2Xl></Tabs.Tab>
                    <Tabs.Tab value="recommendations" leftSection={<IconChecklist/>}><Text2Xl>Recommendations</Text2Xl></Tabs.Tab>
                    <Tabs.Tab value="notes" leftSection={<IconNote/>}><Text2Xl>Notes</Text2Xl></Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="home_details">
                    <EditMissingHomeDetailsPanel
                        assessment={assessment}
                    />
                </Tabs.Panel>
                <Tabs.Panel value="assessment_files">
                    <AssessmentFilesTable
                        assessment={assessment}
                    />
                </Tabs.Panel>
                <Tabs.Panel value="utilities">
                    <EditUtilitiesPanel
                        home={home}
                        assessment={assessment} />
                </Tabs.Panel>
                <Tabs.Panel value="notes">
                    <AssessmentNotesPanel
                        home={home}
                        assessment={assessment}
                    />
                </Tabs.Panel>
                <Tabs.Panel value="recommendations">
                    <AdminRecommendationsPanel
                        home={home}
                        assessment={assessment}
                    />
                </Tabs.Panel>
            </Tabs>
        </FullWithDesignedSection>
    )
}