import {Box, COLOR_WHITE, HStack, FullWithDesignedSection, TextLg, VStack} from "@seeair/shared-components";
import {HomeCollapse} from "./HomeCollapse.js";
import {HomeAggregate, SeeAirUser} from "@seeair/schemas";

export function AdminUserPage({user, homes}: { user?: SeeAirUser, homes: Array<HomeAggregate> }) {
    return <FullWithDesignedSection background={COLOR_WHITE}>
        <VStack>
            <HStack>
                <TextLg>{user ? user.name : "User Not Found"}</TextLg>
            </HStack>
            <Box>
                {homes.map((home) => (
                    <HomeCollapse
                        key={home.home_id}
                        home={home}
                        user={user}
                    />
                ))}
            </Box>
        </VStack>
    </FullWithDesignedSection>
}