import {
    AddressSchema, AirSensorDataSchema, AssessmentFilesSchema, AssessmentNoteSchema,
    AssessmentStatusSchema, EnergyDataRecordSchema, FullAssessmentSchema, HomeDetailsSchema, HubspotEligibilitySurveySchema,
    RecDataSchema,
    SolarPotentialSchema
} from "./jsonb-schemas.js";
import {createInsertSchema, createSelectSchema} from "drizzle-zod";
import {z} from "zod";
import {
    HubspotLineItemSchema,
    HubspotMeetingPropertiesSchema,
} from "./hubspot.js";
import {assessments,homes,shares,recommendations,quotes} from "./drizzle-energy.js";
import {waitlist,users} from "./drizzle-authentication.js";

const refineAssessment = {
    assessment_data: FullAssessmentSchema,
    electric_usage: z.record(z.string(),EnergyDataRecordSchema).nullish(),
    gas_usage: z.record(z.string(),EnergyDataRecordSchema).nullish(),
    air_sensor_data: z.record(z.string(),AirSensorDataSchema).nullish(),
    // weather_data: z.object({}),
    solar_data: SolarPotentialSchema.nullish(),
    assessment_files: AssessmentFilesSchema.nullish(),
    assessment_notes: z.record(z.string(),AssessmentNoteSchema).nullish(),
    home_details: HomeDetailsSchema.nullish(),
    assessment_meetings: z.record(z.string(),HubspotMeetingPropertiesSchema).nullish(),
}
export type Home = z.infer<typeof GetHomeSchema>

export const GetAssessmentSchema = createSelectSchema(assessments, refineAssessment)
export type Assessment = z.infer<typeof GetAssessmentSchema>
export const CreateAssessmentSchema = createInsertSchema(assessments, refineAssessment).omit({assessment_id: true})
export const UpdateAssessmentSchema = createInsertSchema(assessments, refineAssessment)
    .omit({
        // we won't allow updating these fields
        home_id: true,
        created_at: true,
    })
    .partial()
    .required({assessment_id: true})

const refineHome = {
    // home_details: HomeDetailsDBSchema,
    address: AddressSchema,
}
export const GetHomeSchema = createSelectSchema(homes, refineHome)
export const AddHomeSchema = createInsertSchema(homes, refineHome).omit({home_id: true})
export type AddHomeProps = z.infer<typeof AddHomeSchema>
export const GetSharesSchema = createSelectSchema(shares)
export type Share = z.infer<typeof GetSharesSchema>
const refineRecommendation = {
    rec_data:RecDataSchema,
    hubspot_line_items: z.array(HubspotLineItemSchema)
}
export const GetRecommendationsSchema = createSelectSchema(recommendations,refineRecommendation)
export type Recommendation = z.infer<typeof GetRecommendationsSchema>
const refineQuote = {
    hubspot_line_items: z.array(HubspotLineItemSchema)
}
export const GetQuotesSchema = createSelectSchema(quotes,refineQuote)
export type Quote = z.infer<typeof GetQuotesSchema>
export const InsertQuotesSchema = createInsertSchema(quotes,refineQuote)
export type InsertQuote = z.infer<typeof InsertQuotesSchema>
export const CreateQuotesSchema = createInsertSchema(quotes,refineQuote).omit({quote_id:true})
export type CreateQuote = z.infer<typeof CreateQuotesSchema>

export type AssessmentStatus = z.infer<typeof AssessmentStatusSchema>
export const GetUserSchema = createSelectSchema(users)
export type SeeAirUser = z.infer<typeof GetUserSchema>
const refineWaitlist = {
    eligibility_data:HubspotEligibilitySurveySchema
}
export const GetWaitlistSchema = createSelectSchema(waitlist,refineWaitlist)
export type Waitlist = z.infer<typeof GetWaitlistSchema>