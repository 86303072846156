import {createFileRoute} from '@tanstack/react-router'
import {
    TermsOfService,
    Layout,
    PageTitle,
    FullWithDesignedSection, COLOR_WHITE
} from "@seeair/shared-components";
import React from "react";

export const Route = createFileRoute('/terms')({
    component: function TermsPageComponent() {
        return <Layout>
            <FullWithDesignedSection grow background={COLOR_WHITE}>
                <PageTitle text="Terms Of Service"/>
                <TermsOfService/>
            </FullWithDesignedSection>
        </Layout>
    }
})
