import {
	Assessment, HomeDetailsSchema
} from "@seeair/schemas";
import React from "react";
import {SinglePropertySchemaForm} from "./EditMissingHomeDetailsPanel.js";


export function EditHomeDetailsPanel({assessment, readOnly}: {
	assessment: Assessment
	readOnly: boolean }) {

	return <SinglePropertySchemaForm
			readOnly={readOnly}
			data={assessment.home_details || {}}
			schema={HomeDetailsSchema}
			idPrefix="edit-home-details"
			home_id={assessment.home_id}
			assessment_id={assessment.assessment_id}/>
}
