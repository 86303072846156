import {HubspotBlog} from "@seeair/schemas";
import {
    COLOR_LIGHT_GREY, FullWithDesignedSection,
    PageTitle,
    RStack,
    VStack
} from "@seeair/shared-components";
import {BlogBox} from "./BlogBox.js";
import React from "react";

export function BlogIndexPage({blogs}:{blogs:Array<HubspotBlog>}){
    const blogPairs:Array<[HubspotBlog,HubspotBlog?]> =[]
    let unpaired:HubspotBlog|undefined = undefined
    for(const b of blogs){
        if(unpaired==undefined){
            unpaired=b
        } else {
            blogPairs.push([unpaired,b])
            unpaired = undefined
        }
    }
    if(unpaired!=undefined){
        blogPairs.push([unpaired,undefined])
    }
    return <FullWithDesignedSection background={COLOR_LIGHT_GREY}>
        <PageTitle text="Blog"/>
        <VStack center>
            {
                blogPairs.map(([b1,b2])=><RStack lg classNames="gap-x-8">
                    <BlogBox blog={b1} classNames="bg-white w-full lg:w-1/2" />
                    { b2
                        ? <BlogBox blog={b2} classNames="bg-white w-full lg:w-1/2"/>
                        : <div className="w-full lg:w-1/2"/>}
                </RStack>)
            }
        </VStack>
    </FullWithDesignedSection>

}