import {dollarFmt} from "~/lib-client";


import {Recommendation,HomeAggregate} from "@seeair/schemas";
import {RecommendationAction} from "./RecommendationAction.js";
import React from "react";
import {Box, HStack, ProjectIcon, RStack, TextBase, TextLg, VStack} from "@seeair/shared-components";

export function ProjectRow({home,rec,showActionMenu}: {
    home: HomeAggregate
    rec: Recommendation
    showActionMenu?: boolean
}) {
    return (
        <Box classNames="m-4">
            <RStack>
                <HStack>
                    <ProjectIcon lg imgSrc={`/recommendation-icons/${rec.original_rec_id}.png`}/>
                    <TextLg>{rec.title}</TextLg>
                </HStack>
                <VStack>
                    {/* Always */}
                    <TextBase>
                        Upfront Cost: {dollarFmt.format(rec.rec_data.Upfront_Cost_Low)} to{' '}
                        {dollarFmt.format(rec.rec_data.Upfront_Cost_High)}
                    </TextBase>
                    {/* If different than upfront cost */}
                    {(rec.rec_data.Net_Cost_Low !== rec.rec_data.Upfront_Cost_Low ||
                        rec.rec_data.Net_Cost_High !== rec.rec_data.Upfront_Cost_High) && (
                        <TextBase>
                            Net Cost: {dollarFmt.format(rec.rec_data.Net_Cost_Low)} to{' '}
                            {dollarFmt.format(rec.rec_data.Net_Cost_High)}
                        </TextBase>
                    )}
                    {/* If non zero */}
                    {(rec.rec_data.Annual_Savings_Low > 0 ||
                        rec.rec_data.Annual_Savings_High > 0) && (
                        <TextBase>
                            Annual Savings: {dollarFmt.format(rec.rec_data.Annual_Savings_Low)} to{' '}
                            {dollarFmt.format(rec.rec_data.Annual_Savings_High)}
                        </TextBase>
                    )}
                </VStack>
                <RecommendationAction home={home} rec={rec} showMenu={showActionMenu}/>
            </RStack>
        </Box>
    )
}