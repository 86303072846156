import React from 'react'
import {IconCircleCheckFilled, IconExclamationCircle,} from '@tabler/icons-react'
import {ValidationResponse} from "@seeair/schemas";
import {useDisclosure} from "@mantine/hooks";
import { Popover, UnstyledButton} from "@mantine/core";
import {TextSm, VStack} from './DesignBase.js';

export function HomeownerInputCheck({validationResponse}: { validationResponse: ValidationResponse }) {
    const [opened, {close, open}] = useDisclosure(false);
    return (
        <div className="flex flex-row mx-4">
            {
                validationResponse.valid
                    ? <IconCircleCheckFilled className="text-green-500"/>
                    : <Popover position="bottom" shadow="md" opened={opened}>
                        <Popover.Target>
                            <UnstyledButton onMouseEnter={open} onMouseLeave={close}>
                                <IconExclamationCircle className="text-yellow-500"/>
                            </UnstyledButton>
                        </Popover.Target>
                        <Popover.Dropdown className="border-0 bg-black p-4 rounded-2xl">
                            <VStack>
                                {validationResponse.errors.map(e => <TextSm inverse>{e}</TextSm>)}
                            </VStack>
                        </Popover.Dropdown>
                    </Popover>
            }
        </div>
    )
}
