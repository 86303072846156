import {createFileRoute} from '@tanstack/react-router'
import {HomePage} from "@seeair/homeowner-components";

export const Route = createFileRoute('/')({
	component: function HomePageComponent(){
		// const {user} = useContext(UserDataContext)
		// const navigate = useNavigate()
		//
		// useEffect(() => {
		// 	if(user) {
		// 		(async ()=>await navigate({to: '/homes'}))()
		// 	}
		// },[user,navigate])

		return <HomePage />
	},
})
