import {READONLY_TOOLTIP, trpc} from "~/lib-client";
import {ReactElement, useCallback, useContext} from "react";
import {modals} from "@mantine/modals";
import {Button, Loader, Menu, Tooltip, UnstyledButton} from "@mantine/core";
import {IconDots, IconExternalLink} from "@tabler/icons-react";
import {Recommendation,HomeAggregate} from "@seeair/schemas";
import { VStack} from "@seeair/shared-components";
import {SheetsDataContext} from "@seeair/shared-components";


export function RecommendationAction({home,rec,showMenu:_showMenu}: {
    home: HomeAggregate
    rec: Recommendation
    showMenu?: boolean
}) {
    const {recommendationsSheet} = useContext(SheetsDataContext)
    const showMenu = (home.permissions?.has('write') ?? false) && _showMenu
    const utils = trpc.useUtils()

    const _advanceRec = trpc.HOMEOWNER.changeRecommendationStatus.useMutation({
        onSettled: () => {
            utils.HOMEOWNER.getHomeAggregate.invalidate()
            utils.ADMIN.getHomesAggregateForUser.invalidate()
        },
    })
    const advanceRec = useCallback(
        (status: Recommendation['status']) => {
            return _advanceRec.mutate({
                home_id: rec.home_id,
                recommendation_id: rec.recommendation_id,
                status,
            })
        },
        [_advanceRec, rec.home_id, rec.recommendation_id]
    )
    const hasWritePermission = home.permissions && !home.permissions.has('write')
    if (rec.status === 'not_started') {
        if (!hasWritePermission) {
            return null
        }
        return (
            <Button
                onClick={() => {
                    modals.openConfirmModal({
                        title: 'Start project?',
                        children: (
                            <div>
                                This project will be added to your Home Improvement Plan. Please add any
                                other projects you would like to consider. <br/>
                                <br/>A member of our team will be in touch shortly to talk through next
                                steps and answer any questions.
                            </div>
                        ),
                        labels: {confirm: 'Confirm', cancel: 'Cancel'},
                        onConfirm: () => advanceRec('in_progress'),
                    })
                }}
            >
                Start Project
                {_advanceRec.isPending && <Loader size="1rem"/>}
            </Button>
        )
    } else if (rec.status === 'done') {
        return (
            <>
                <Button
                    onClick={() => window.open("https://www.seeair.com/store/p/seeair-reevaluation", "_blank")}
                >
                    Start Verification
                    <IconExternalLink/>
                </Button>
                {showMenu && (
                    <Menu>
                        <Menu.Target>
                            <UnstyledButton>
                                <IconDots size="1.5rem"/>
                            </UnstyledButton>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item
                                onClick={() => advanceRec('not_started')}
                                disabled={_advanceRec.isPending}
                            >
                                Mark as In Progress
                                {_advanceRec.isPending && <Loader size="1rem"/>}
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                )}
            </>
        )
    } else {
        const recSheetRec = recommendationsSheet.find((row) => row.id === rec.original_rec_id)

        let btn:ReactElement | null = null
        if (rec.type === 'DIY') {
            btn = ( recSheetRec?.projectUrl ?
                <Button
                    onClick={() => window.open(recSheetRec!.projectUrl!, "_blank")}
                >
                    {recSheetRec?.projectButtonText ?? 'Start Project'}
                    <IconExternalLink/>
                </Button>
                    : null
            )
        } else {
            if (rec.selected_quote_id) {
                btn = (
                    <Tooltip label={hasWritePermission ? undefined : READONLY_TOOLTIP}>
                        <Button
                            onClick={() => {
                                modals.openConfirmModal({
                                    title: 'Mark as complete',
                                    children: <div>This project will be marked as complete!</div>,
                                    labels: {confirm: 'Confirm', cancel: 'Cancel'},
                                    onConfirm: () => advanceRec('done'),
                                })
                            }}
                            disabled={!hasWritePermission}
                        >
                            Mark as Complete
                            {_advanceRec.isPending && <Loader size="1rem"/>}
                        </Button>
                    </Tooltip>
                )
            } else {
                btn = null
            }
        }

        return (
            <VStack>
                {btn}
                {showMenu && (
                    <Menu>
                        <Menu.Target>
                            <UnstyledButton>
                                <IconDots size="1.5rem"/>
                            </UnstyledButton>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item
                                onClick={() => advanceRec('done')}
                                disabled={_advanceRec.isPending}
                            >
                                Mark as Complete
                                {_advanceRec.isPending && <Loader size="1rem"/>}
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                )}
            </VStack>
        )
    }
}