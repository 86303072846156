import {COLOR_GREEN, COLOR_ORANGE, COLOR_WHITE, COLOR_YELLOW} from './Theme.js';
import {FullWithDesignedSection, HStack, RStack, Text4Xl, TextLg, VStack} from './DesignBase.js';
import {AssessmentIllustration, DoneIllustration, PlanIllustration} from './Illustrations.js';
import {NumberCircle} from './Icons.js';
import React from "react";

export function DesignedIllustrationSection() {
    return <FullWithDesignedSection background={COLOR_WHITE} minHeight="100rem"><VStack>
        <HStack center>
            <div className="pb-12">
                <Text4Xl center>Assess, Plan and Complete Projects for a Healthy & Sustainable Home</Text4Xl>
            </div>
        </HStack>
        <RStack md reverse>
            <AssessmentIllustration/>
            <VStack>
                <NumberCircle number={"1"} color={COLOR_ORANGE}/>
                <Text4Xl>The Home Energy & Health Assessment</Text4Xl>
                <TextLg>SeeAir’s no-cost energy & health assessment is designed to be fast, easy and free. We’ll bring
                    the building science and technology to bring you up to speed on how your home is
                    performing.</TextLg>
            </VStack>
        </RStack>
        <RStack md>
            <VStack>
                <NumberCircle number={"2"} color={COLOR_GREEN}/>
                <Text4Xl>The Home Improvement Plan</Text4Xl>
                <TextLg>Every home is different and so are your priorities. Our experts will walk you through the home
                    improvement options specific to your home so you can choose what solves your most pressing
                    issues.</TextLg>
            </VStack>
            <PlanIllustration/>
        </RStack>
        <RStack md reverse>
            <DoneIllustration/>
            <VStack>
                <NumberCircle number={"3"} color={COLOR_YELLOW}/>
                <Text4Xl>Project Completion</Text4Xl>
                <TextLg>Whether you are trying to improve energy efficiency or go all electric, we’ll work with you
                    every step of the way to ensure high quality work with reliable contractors at a great price.
                </TextLg>
            </VStack>
        </RStack>
    </VStack>
    </FullWithDesignedSection>
}