import {z} from "zod";
import {HubspotEligibilitySurveySchema} from './jsonb-schemas.js';

export const HUBSPOT_DEAL_PIPELINE_ASSESSMENT = '114638991'
export const HUBSPOT_DEAL_PIPELINE_PROJECT = 'default'

export const HubspotContactPropertiesSchema = z.object({
    lifecyclestage:z.string(),
    hubspot_owner_id:z.coerce.string()
})

export const HubspotContactSchema = z.object({
    id:z.coerce.string(),
    properties:HubspotEligibilitySurveySchema.merge(HubspotContactPropertiesSchema)
})
export type HubspotContact = z.infer<typeof HubspotContactSchema>

export const HubspotLineItemSchema = z.object({
    id:z.coerce.string(),
    properties:z.object({
        hs_sku:z.string().nullish(),
        quantity:z.string(),
        price:z.string(),
        discount:z.string().nullish(),
        tax:z.string().nullish(),
        description:z.string()
    })
})
export type HubspotLineItem = z.infer<typeof HubspotLineItemSchema>
export const HubspotDealSchema = z.object({
    id:z.coerce.string(),
    properties:z.object({
        pipeline:z.string().nullish(),
        hs_lastmodifieddate:z.string().datetime().nullish(),
        seeair_recommendation_id:z.string().nullish(),
        seeair_assessment_id:z.string().nullish(),
        seeair_recommendation_number:z.string().nullish(),
        dealstage:z.string().nullish(),
        dealname:z.string(),
        seeair_recommendation_status:z.string().nullish(),
        seeair_assessment_status:z.string().nullish(),
        hubspot_owner_id:z.coerce.string()
    })
})
export type HubspotDeal = z.infer<typeof HubspotDealSchema>
export const HubspotDealAggregateSchema = HubspotDealSchema.merge(z.object({
    stage_name:z.string().nullish(),
    lineItems:z.array(HubspotLineItemSchema)
}))
export type HubspotDealAggregate = z.infer<typeof HubspotDealAggregateSchema>
export const HubspotMeetingPropertiesSchema = z.object({
    hs_meeting_start_time:z.string().datetime().nullish(),
    hs_meeting_end_time:z.string().datetime().nullish(),
    hs_meeting_title:z.string().nullish(),
    hs_meeting_location:z.string().nullish(),
    hs_meeting_outcome:z.string().nullish(),
})
export type HubspotMeetingProperties = z.infer<typeof HubspotMeetingPropertiesSchema>
export const HubspotMeetingSchema = z.object({
    id:z.coerce.string(),
    properties:HubspotMeetingPropertiesSchema,
    contact:HubspotContactSchema
})
export type HubspotMeeting = z.infer<typeof HubspotMeetingSchema>
export const HubspotQuotePropertiesSchema = z.object({
    hs_createdate:z.string().datetime().nullish(),
    hs_lastmodifieddate:z.string().datetime().nullish(),
    hs_quote_link:z.string().url().nullish(),
    hs_status:z.string().nullish(),
    hs_title:z.string().nullish(),
    hs_quote_amount:z.string().nullish(),
})
export const HubspotQuoteSchema = z.object({
    id:z.coerce.string(),
    properties:HubspotQuotePropertiesSchema,
    lineItems:z.array(HubspotLineItemSchema)
})
export type HubspotQuote = z.infer<typeof HubspotQuoteSchema>
export type HubspotCrmEntity = 'contact'|'deal'|'quote'|'line_items'|'meeting'
export type HubspotCmsEntity = 'posts' | 'authors'
export const HubspotPipelineStage = z.object({
    id:z.coerce.string(),
    label:z.string()
    //there are more that we don't need
})
export const HubspotBlogSchema = z.object({
    id:z.coerce.string(),
    slug:z.string(),
    name:z.string(),
    postBody:z.string(),
    currentlyPublished:z.boolean(),
    publishDate:z.string().datetime(),
    metaDescription:z.string().nullish(),
    featuredImage:z.string().nullish(),
    authorName:z.string().nullish(),
    blogAuthorId:z.string().nullish(),
})
export type HubspotBlog = z.infer<typeof HubspotBlogSchema>
export const HubspotAuthorSchema = z.object({
    id:z.coerce.string(),
    slug:z.string(),
    email:z.string().nullish(),
    displayName:z.string(),
    bio:z.string().nullish(),
    avatar:z.string().url().nullish()
})
export type HubspotAuthor = z.infer<typeof HubspotAuthorSchema>
export const unknownHubspotAuthor = {
    id: "",
    bio: null,
    email: null,
    avatar: null,
    slug: "",
    displayName: "Unknown"
}
export const HubspotBlogAggregateSchema = z.object({
    author:HubspotAuthorSchema
}).merge(HubspotBlogSchema)
export type HubspotBlogAggregate = z.infer<typeof HubspotBlogAggregateSchema>