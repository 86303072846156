import {createFileRoute, redirect} from '@tanstack/react-router'
import {TextBase, TextLg, VStack} from "@seeair/shared-components";
import React from "react";
import {z} from "zod";

export const Route = createFileRoute('/auth/_auth/error')({
    beforeLoad(ctx) {
        if (ctx.context.user) {
            throw redirect({to: '/'})
        }
    },
    validateSearch: z.object({error: z.string().optional()}),
    component: function ErrorComponent() {
        const {error} = Route.useSearch()
        let errorMessage:string
        if(error == "Configuration") {
            errorMessage = "There was an issue with the auth configuration, please reach out to support@seeair.com to report this issue"
        } else {
            errorMessage = `There was an unrecognized error with the code ${error}, please reach out to support@seeair.com to report this issue`
        }
        return <VStack grow center classNames="bg-white">
            <TextLg red>There was an authentication error</TextLg>
            <TextBase>{errorMessage}</TextBase>
        </VStack>
    },
})
