import {createFileRoute, redirect} from '@tanstack/react-router'
import {z} from 'zod'
import {AuthError, SignIn, VStack} from "@seeair/shared-components";

export const Route = createFileRoute('/auth/_auth/signin')({
    component: function SignInComponent() {
        const {redirect = '/',error} = Route.useSearch()
        return <VStack grow center classNames="bg-white">
            {
                error
                    ?<AuthError error={error} />
                    :<SignIn redirect={redirect}/>
            }

        </VStack>
    },
    validateSearch: z.object({redirect: z.string().optional(),error:z.string().optional()}),
    beforeLoad(ctx) {
        if (ctx.context.user) {
            const to = ctx.search.redirect
                ? new URL(decodeURIComponent(ctx.search.redirect)).pathname
                : '/'
            console.log(`user is already logged in, redirecting to: ${to}`)
            throw redirect({to})
        }
    },
})


