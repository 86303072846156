import {createFileRoute} from "@tanstack/react-router";
import {SheetsDataProvider} from "@seeair/shared-components";
import {AuthenticatedLoad, trpc} from "~/lib-client";
import React from "react";
import {MobileRecommendationsPage} from "@seeair/homeowner-components";

export const Route = createFileRoute('/webview/home/$home_id/recommendations')({
    component: function RecommendationsWebView() {
        const {home_id} = Route.useParams()
        const [home] = trpc.HOMEOWNER.getHomeAggregate.useSuspenseQuery({home_id})

        return <SheetsDataProvider><MobileRecommendationsPage home={home} /></SheetsDataProvider>
    },
    beforeLoad: AuthenticatedLoad

})