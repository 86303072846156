import {RecommendationAggregate} from "@seeair/schemas";
import {Table} from "@mantine/core";

export function QuotesTable({rec}: { rec: RecommendationAggregate }) {
    const rows: string[][] = (rec.quotes || []).reduce((acc, v) => [...acc, [
        v.quote_title || '',
        v.hubspot_quote_amount || '',
        v.quote_url || '']], [] as string[][])
    return <Table
    striped
    withTableBorder
    captionSide="bottom"
        data={{
        caption: `${rows.length} Quotes from Hubspot`,
        head: ['Title','Amount','URL'],
        body: rows

    }} />
}