import {Accordion, AccordionControl, AccordionPanel, Button, Loader, Switch, Table} from "@mantine/core";
import {Assessment, HomeAggregate, RecommendationAggregate} from "@seeair/schemas";
import {NewRecommendationInputPanel} from './NewRecommendationInputPanel.js';
import {
    DesignedButton, formatMoney, HStack,
    LineItemTable,
    ProjectIcon, QuotesTable,
    Text2Xl,
    TextBase,
    TextLg, TextLink,
    VStack
} from "@seeair/shared-components";
import {trpc} from "~/lib-client";
import {notifications} from "@mantine/notifications";
import {IconTrash} from "@tabler/icons-react";

export function AdminRecommendationsPanel({home, assessment}: {
    home: HomeAggregate,
    assessment: Assessment
}) {
    const utils = trpc.useUtils();

    const {
        isPending: isAutomatedAssessmentRunning,
        isError: isRunAssessmentError,
        error: runAssessmentError,
        mutate: runAutomatedAssessment
    } = trpc.ADMIN.runAutomatedAssessment.useMutation({
        onSuccess: () => {
            utils.ADMIN.getHomesAggregateForUser.invalidate();
            notifications.show({message: 'Success!'})
        },
        onError: (e) =>
            notifications.show({message: `Failure: ${e.message}`})
    });
    const thisRecs = home.recommendations.filter(r => r.assessment_id == assessment.assessment_id)
    const floatingRecs = home.recommendations.filter(r => !r.assessment_id)
    return (
        <VStack>
            <RecAccordion recs={thisRecs} />
            <div>
                <DesignedButton onClick={() => runAutomatedAssessment({home_id: home.home_id, assessment_id: assessment.assessment_id})}
                                disabled={isAutomatedAssessmentRunning}>
                    {isAutomatedAssessmentRunning ? 'Processing...' : 'Run Automated Assessment'}
                </DesignedButton>
            </div>
            <div>
                <NewRecommendationInputPanel assessment_id={assessment.assessment_id} home_id={home.home_id}/>
            </div>
            {floatingRecs.length > 0 && <RecAccordion recs={floatingRecs} />}

        </VStack>
    )
}
export function RecAccordion({recs}:{recs:Array<RecommendationAggregate>}) {
    return <Accordion>
        {recs.map((r,i)=><Accordion.Item key={i} value={`${i}`}>
            <AccordionControl><RecControl rec={r}/></AccordionControl>
            <AccordionPanel><RecPanel rec={r}/></AccordionPanel>
        </Accordion.Item>)}
    </Accordion>
}
function RecControl({rec}:{rec:RecommendationAggregate}) {
    return <HStack leftCenter>
        <ProjectIcon imgSrc={`/recommendation-icons/${rec.original_rec_id}.png`} />
        <Text2Xl medium>{rec.original_rec_id}</Text2Xl>
        <TextLg classNames="ml-4">{rec.title}</TextLg>
    </HStack>
}
function RecPanel({rec}:{rec: RecommendationAggregate}) {
    const utils = trpc.useUtils()
    const {
        isPending:isDeleteRecPending,
        isError:isDeleteRecError,
        error:deleteRecError,
        mutate:deleteRec
    } = trpc.ADMIN.deleteRecommendation.useMutation({
        onSuccess: ()=>{
            utils.ADMIN.invalidate()
        },
        onError:(error) => {
            notifications.show({message: `Failure: ${error.message}`})
        }
    })
    const {
        isPending:isSetRecHiddenPending,
        isError:isSetRecHiddenError,
        error:setRecHiddenError,
        mutate:setRecHidden
    } = trpc.ADMIN.setRecommendationsHidden.useMutation({
        onSuccess: ()=>{
            utils.ADMIN.invalidate()
        },
        onError:(error) => {
            notifications.show({message: `Failure: ${error.message}`})
        }
    })
    return <VStack classNames="first:border-t-0 border-t-2 border-black py-4"
                   key={rec.recommendation_id}>
        <HStack leftCenter>
            <TextLg classNames="mr-4">Hubspot Deal</TextLg>
            {
                rec.hubspot_deal_id
                    ? <a href={`https://app.hubspot.com/contacts/46365478/record/0-3/${rec.hubspot_deal_id}`}><TextLink>{rec.hubspot_deal_id}</TextLink></a>
                    : <TextBase>None</TextBase>
            }
        </HStack>
        <TextLg medium>Financials</TextLg>
        <RecommendationFinancialsTable rec={rec}/>
        <TextLg medium>Line Items</TextLg>
        <LineItemTable rec={rec}/>
        <TextLg medium>Quotes</TextLg>
        <QuotesTable rec={rec} />
        <HStack between>
            <Switch
                className="my-4"
                size="xl"
                defaultChecked={!rec.hidden || false}
                onChange={(e)=>{
                    setRecHidden({
                        home_id:rec.home_id,
                        recommendation_id:rec.recommendation_id,
                        hidden:!e.currentTarget.checked})
                }}
                onLabel="Visible"
                offLabel="Hidden"
                label="Visiblity"
                disabled={isSetRecHiddenPending || isSetRecHiddenError}
            />
            <Button
                size="xl"
                onClick={() => {
                    deleteRec({
                        recommendation_id:rec.recommendation_id
                    })
                }}
                disabled={isDeleteRecPending}
            >
                {
                    isDeleteRecPending?<Loader />:<IconTrash size="1rem"/>
                }

            </Button>
        </HStack>

    </VStack>
}
function RecommendationFinancialsTable({rec}:{rec:RecommendationAggregate}) {
    const rows: string[][] = [
        ["Upfront Cost",formatMoney(rec.rec_data.Upfront_Cost_High),formatMoney(rec.rec_data.Upfront_Cost_Low)],
        ["Net Cost",formatMoney(rec.rec_data.Net_Cost_High),formatMoney(rec.rec_data.Net_Cost_Low)],
        ["Annual Savings Cost",formatMoney(rec.rec_data.Annual_Savings_High),formatMoney(rec.rec_data.Annual_Savings_Low)],
    ]
    return <Table
        striped
        withTableBorder
        captionSide="bottom"
        data={{
            caption: `Estimated Costs and Savings`,
            head: ['','High','Low',],
            body: rows

        }}/>
}