import React, {ReactElement} from 'react';
import {HomeAggregate} from "@seeair/schemas";
import {groupRecs} from "~/lib-client";
import { TextLg, TextLink, VStack} from "@seeair/shared-components";
import {ProjectRow} from "./ProjectRow.js";
import {Link} from "@tanstack/react-router";

export function HomeImprovementPlanSummary({home}: { home: HomeAggregate }) {
    const allRecommendations = home.recommendations || []
    const recs = groupRecs(allRecommendations)
    let component:ReactElement
    if(allRecommendations.length == 0) {
        component = <TextLg center>
            Your home is energy efficient and fully electric. Thank you for doing your
            part to reduce your carbon emissions!
        </TextLg>
    } else if((recs.active ?? []).length == 0) {
        component = <TextLg center>
            You haven't started any projects yet, go to your Projects page to begin!
        </TextLg>
    } else {
        component = <React.Fragment>
            {recs.active?.map((rec) => (
                <ProjectRow key={rec.recommendation_id} home={home} rec={rec} showActionMenu={false}/>
            ))}
        </React.Fragment>
    }
    return (
        <VStack center>
            {component}
            {allRecommendations.length > 0 && (
                <Link
                    preload={false}
                    to="/homes/$home_id/improvement-plan"
                    params={{home_id: home.home_id}}
                >
                    <TextLink>Manage Projects</TextLink>
                </Link>
            )}
        </VStack>
    )
}