import {createFileRoute} from "@tanstack/react-router";
import {AdminOnlyLoad, trpc} from "~/lib-client";
import {AdminAssessmentPage} from "@seeair/admin-components";
import {TextError} from "@seeair/shared-components";

export const Route = createFileRoute('/admin/_admin/user/$user_id/home/$home_id/assessment/$assessment_id')({
    component: function AdminAssessmentPageComponent() {
        const {user_id, home_id, assessment_id} = Route.useParams()
        const [homes] = trpc.ADMIN.getHomesAggregateForUser.useSuspenseQuery({user_id})
        const home = homes.find(h => h.home_id == home_id)
        const assessment = home?.assessments?.find(a => a.assessment_id == assessment_id)
        if (!home || !assessment) {
            return <TextError>Assessment Not Found</TextError>
        }
        return <AdminAssessmentPage
            home={home}
            assessment={assessment}
        />
    },
    wrapInSuspense: true,
    beforeLoad: AdminOnlyLoad
})


