import { Avatar} from "@mantine/core";



import {SeeAirUser} from "@seeair/schemas";
import {Link} from "@tanstack/react-router";
import { LinkRow, TextSm, VStack} from "@seeair/shared-components";

export function UserCollapse(props: {
    userData: SeeAirUser
}) {
    const {userData} = props

    return (
        <Link preload={false} to="/admin/user/$user_id" params={{user_id: userData.id}}>
            <LinkRow>
                <Avatar
                    src={userData.image}
                    imageProps={{referrerPolicy: 'no-referrer'}}
                />
                <VStack>
                    <TextSm>
                        {userData.name}
                    </TextSm>
                    <TextSm>
                        {userData.email}
                    </TextSm>
                </VStack>
            </LinkRow>
        </Link>
    )
}