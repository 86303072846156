import {QueryClient, QueryClientProvider, useQueryClient} from '@tanstack/react-query'
import {
    ErrorComponent,
    Link,
    RouterProvider,
    createRouter as rawCreateRouter,
} from '@tanstack/react-router'
import {httpBatchLink} from '@trpc/client'

import {RouterContext, initSuperJSON, trpc} from '~/lib-client'
import {routeTree} from '~/routeTree.gen'
import React, {Suspense, useState} from 'react'
import {MantineProvider} from '@mantine/core'
import {ModalsProvider} from '@mantine/modals'
import {Notifications} from '@mantine/notifications'
import SuperJSON from 'superjson'
import {captureException} from '@sentry/react'
import {
    ErrorBoxPage,
    Layout,
    mantineTheme, QueryUserDataProvider,
    SuspenseLoader,
    TextLink,
    TextXl
} from "@seeair/shared-components";

initSuperJSON()

const LoggedErrors = new WeakSet()
const createRouter = (context: RouterContext) => {
    return rawCreateRouter({
        routeTree,
        context,
        defaultPendingComponent: () => SuspenseLoader,
        defaultErrorComponent: (props) => {
            if (props.error && !LoggedErrors.has(props.error)) {
                LoggedErrors.add(props.error)
                captureException(props.error)
            }
            return <ErrorComponent {...props} />
        },
        // Should be intent, but currently some bugs where preload will navigate to the page in some scenarios??
        // https://github.com/TanStack/router/issues/1382
        defaultPreload: 'intent',
        notFoundMode: 'root',
        defaultNotFoundComponent: () => (
            <Layout>
                <ErrorBoxPage>
                    <TextXl>Not Found</TextXl>
                    <Link
                        preload={false}
                        to="/"
                    >
                        <TextLink>Go home</TextLink>
                    </Link>
                </ErrorBoxPage>
            </Layout>
        ),
    })
}

declare module '@tanstack/react-router' {
    interface Register {
        router: ReturnType<typeof createRouter>
    }
}

function AppRouter() {
    const queryClient = useQueryClient()
    const utils = trpc.useUtils()
    const [user] = trpc.PUBLIC.getCurrentUser.useSuspenseQuery()
    const [router] = useState(() => createRouter({queryClient, utils, user: user || null}))

    return <RouterProvider router={router}/>
}

export function App() {
    const [queryClient] = useState(() => {
        return new QueryClient({
            defaultOptions: {
                queries: {
                    staleTime: 15 * 1000,
                    retry: 1,
                    retryDelay: (i) => 3 + i * 3,
                },
            },
        })
    })
    const [trpcClient] = useState(() => {
        return trpc.createClient({
            links: [httpBatchLink({url: '/api/trpc', transformer: SuperJSON})],
        })
    })
    return (
        <React.StrictMode>
            <Suspense fallback={SuspenseLoader}>
                <trpc.Provider client={trpcClient} queryClient={queryClient}>
                    <QueryClientProvider client={queryClient}>
                        <QueryUserDataProvider>
                            <MantineProvider theme={mantineTheme}>
                                <ModalsProvider>
                                    <Notifications/>
                                    <AppRouter/>
                                </ModalsProvider>
                            </MantineProvider>
                        </QueryUserDataProvider>
                    </QueryClientProvider>
                </trpc.Provider>
            </Suspense>
        </React.StrictMode>
    )
}

