import {createFileRoute} from '@tanstack/react-router'
import {AuthenticatedLoad, trpc} from '~/lib-client'
import {MobileRecommendationsPage} from "@seeair/homeowner-components";


export const Route = createFileRoute('/homes/_homes/$home_id/improvement-plan')({
	component: function HomeImprovementPlanRoute(){
		const {home_id} = Route.useParams()

		const [home] = trpc.HOMEOWNER.getHomeAggregate.useSuspenseQuery({home_id})
		return <MobileRecommendationsPage home={home} />
	},
	wrapInSuspense: true,
	beforeLoad: AuthenticatedLoad,
	loader({context:{utils},params:{home_id}}) {
		utils.HOMEOWNER.getHomeAggregate.prefetch({home_id})
	},
})

