import {HubspotBlog} from "@seeair/schemas";
import {DesignedLink, TextBase, TextSm, TextXl} from "@seeair/shared-components";
import classNames from "classnames";
import React from "react";
import {shortDateFormat} from "@seeair/shared-components";

export function BlogBox({blog,classNames:cns}:{blog:HubspotBlog,classNames?:string}){
    return <div className={classNames("flex flex-row p-8 my-4 rounded-2xl",cns)}>
        <img src={blog.featuredImage||'/design-assets/img-placeholder.svg'} height={192} width={192/*12rem*/} className="rounded-2xl object-cover"/>
        <div className="h-48">
            <div className="flex flex-col mx-8 h-44 overflow-hidden">
                <TextXl m0>{blog.name}</TextXl>
                <TextSm light m0>{shortDateFormat(blog.publishDate)}</TextSm>
                <TextBase m0 classNames="mt-2 mb-4">{blog.metaDescription}</TextBase>
            </div>
            <div className="ml-8 mb-4">
                <DesignedLink to={`/blog/${blog.id}`}>
                    <span className="underline">{"Learn More >"}</span>
                </DesignedLink>
            </div>
        </div>
    </div>
}