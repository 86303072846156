import {
    BorderlessIconCard,
    COLOR_LIGHT_GREY,
    COLOR_WHITE,
    DesignedButton, GradientBox,
    HSpacer,
    IconAmbulance,
    IconCapitol,
    Layout,
    PRIMARY_BLUE,
    RStack,
    FullWithDesignedSection,
    Text2Xl,
    Text3Xl,
    Text4Xl,
    TextBase,
    IconTree,
    TextLg,
    TextXl,
    VStack, DesignedPlayButton
} from "@seeair/shared-components";
import YouTube from "react-youtube";
import { Modal} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {BlogSection} from './BlogSection.js';
// import {IconTree} from "@tabler/icons-react";

const logoClassName = "object-scale-down w-52 max-h-32"
const logoLinkClassName = "my-6 md:my-2"

export function AboutPage() {
    const [youtubeOpen, setYoutubeOpen] = useState(false)
    const [windowSize,setWindowSize] = useState({width:window.innerWidth,height:window.innerHeight})
    useEffect(() => {
        window.addEventListener('resize',()=>setWindowSize({width:window.innerWidth,height:window.innerHeight}))

    }, []);
    return (
        <Layout newsletterSurvey headerOverlap>
            {
                youtubeOpen && <Modal size="90vw" classNames={{header:"hidden",content:"overflow-hidden",body:"p-0 bg-black"}} opened onClose={() => setYoutubeOpen(false)}>
                    <YouTube videoId={"L679nQanqYQ"} title={"SeeAir"} opts={{width: (windowSize.width*.9),height: (windowSize.height*.9), playerVars: {autoplay: 1,showinfo:0}}}/>
                </Modal>
            }
            <section>
                    <div className="aspect-square lg:aspect-video relative">
                        <video src="/design-assets/about-banner.webm" loop muted playsInline autoPlay className="absolute top-0 left-0 w-full h-full object-cover" />
                        <DesignedPlayButton text="About Us" onClick={()=>setYoutubeOpen(true)}/>
                    </div>
            </section>
            <FullWithDesignedSection background={`url(/design-assets/background3.png) ${PRIMARY_BLUE} 50% / cover no-repeat`}>
                <RStack>
                    <HSpacer/>
                    <div style={{width: "100%", height: "100%"}}>
                        <Text3Xl inverse m0>In a rapidly changing world where modern technology is reshaping
                            transportation, medicine, and agriculture,
                            our homes are stuck in the past.</Text3Xl>
                        <br />
                        <Text4Xl inverse classNames="mt-4">We're here to change that, one home at a time.</Text4Xl>
                    </div>
                </RStack>
            </FullWithDesignedSection>
            <FullWithDesignedSection background={COLOR_LIGHT_GREY}>
                <RStack lg>
                    <RStack sm around>
                        <a href="https://www.masssave.com/" className={logoLinkClassName}><img className={logoClassName} src="/design-assets/mass-save-partner.svg"/></a>
                        <a href="https://bpi.org/standards/" className={logoLinkClassName}><img className={logoClassName} src="/design-assets/bpi.png"/></a>
                    </RStack>
                    <RStack sm around>
                        <a href="https://innovationlabs.harvard.edu/" className={logoLinkClassName}><img className={logoClassName} src="/design-assets/harvard-innovation-lab.png"/></a>
                        <a href="https://www.ewor.com/" className={logoLinkClassName}><img className={logoClassName} src="/design-assets/ewor.webp"/></a>
                    </RStack>
                </RStack>
            </FullWithDesignedSection>
            <FullWithDesignedSection background={'linear-gradient(51deg, #14C693 -21.6%, #FFF 19.49%, #FFF 81.1%, #14C693 132.27%)'}>
                <Text4Xl wFull center>Meet The Team</Text4Xl>
                <RStack classNames="mt-8">
                    <Bio name="Piers MacNaughton" title="Co-Founder and CEO" imgSrc="/design-assets/piers.jpg"
                         text="Piers is a healthy building expert who founded SeeAir to help homeowners and their families live healthier lives. He completed his PhD in Green Buildings and Health at the Harvard T.H. Chan School of Public Health before holding several leadership positions at View, a climate-tech company based in Silicon Valley. Piers has two national titles in Ultimate Frisbee, both with his teammate Jake."/>
                    <Bio name="Jake Taylor" title="Co-Founder and CTO" imgSrc="/design-assets/jake.jpg"
                         text="Jake co-founded SeeAir shortly after purchasing his first home, which he is currently converting to be all-electric. Previously, Jake was the co-founder and CTO of Envoy AI, a marketplace for AI in medical imaging. Following its acquisition by TeraRecon, he joined Toast as an engineering leader. After a busy day at work, you can find Jake cooking a vegetarian meal on his induction stovetop."/>
                </RStack>
                <VStack wHalf>
                    <GradientBox maxWidth={100}>
                        <Text3Xl>Careers</Text3Xl>
                        <TextLg m0>Passionate about homes, health, climate change or technology?</TextLg>
                        <TextLg classNames="my-0">Join us on our mission to improve resident health while tackling climate
                            change.</TextLg>
                        <DesignedButton className="ml-0"><a href="mailto:careers@seeair.com"><TextBase inverse>Contact
                            Us</TextBase></a></DesignedButton>
                    </GradientBox>
                </VStack>
            </FullWithDesignedSection>
            <FullWithDesignedSection background={COLOR_WHITE}>
                <Text4Xl wFull center>Why Existing Homes</Text4Xl>
                <VStack center classNames="mt-8">
                    <VStack style={{width: "auto"}}>
                        <BorderlessIconCard gradientColor="rgba(20, 198, 147, 0.24)" icon={<IconTree strokeWidth={.5}/>}
                                            emphasis="> 20%"
                                            text="of the world’s carbon emissions"
                                            info="US Energy Information Administration, Monthly energy review-September 2019, (DOE/EIA‐0035[2019/9]."/>
                        <BorderlessIconCard gradientColor="rgba(249, 114, 88, 0.24)" icon={<IconAmbulance strokeWidth={.5}/>}
                                            emphasis="> 75%"
                                            text="reduction in asthma hospital visits after renovating housing"
                                            info="Meryl D. Colton, Jose Guillermo Cedeno Laurent, Piers MacNaughton, John Kane, Mae Bennett-Fripp, John Spengler, and Gary Adamkiewicz, 2015: Health Benefits of Green Public Housing: Associations With Asthma Morbidity and Building-Related Symptoms American Journal of Public Health 105, 2482-2489."/>
                        <BorderlessIconCard gradientColor="rgba(255, 253, 130, 0.60)" icon={<IconCapitol strokeWidth={.5}/>}
                                            emphasis="< %1"
                                            text="annual renovation rate to current building codes & energy standards"
                                            info="U.S. Energy Information Agency, Residential Energy Consumption Survey, 2015-2020. https://www.eia.gov/consumption/residential/index.php"/>
                    </VStack>
                </VStack>
            </FullWithDesignedSection>
            <BlogSection title={"News & Thought Leadership"} />
        </Layout>
    )
}

function Bio({name, title, imgSrc, text}: { name: string, title: string, imgSrc: string, text: string }) {
    return <VStack center classNames="px-8 md:pb-16">
        <Text2Xl center m0>{name}</Text2Xl>
        <TextXl light m0 classNames="mb-4">{title}</TextXl>
        <img className="rounded-xl max-w-80" src={imgSrc}/>
        <TextBase classNames="max-w-96">
            {text}
        </TextBase>
    </VStack>
}
