import {createFileRoute} from '@tanstack/react-router'
import {COLOR_WHITE, FullWithDesignedSection, Layout, PageTitle, PrivacyPolicy} from "@seeair/shared-components";
import React from "react";

export const Route = createFileRoute('/privacy')({
    component: function PrivacyPageComponent() {
        return <Layout>
            <FullWithDesignedSection grow background={COLOR_WHITE}>
                <PageTitle text="Privacy Policy"/>
                <PrivacyPolicy/>
            </FullWithDesignedSection>
        </Layout>
    }
})
